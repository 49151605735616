import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LinkRouter from '../../components/LinkRouter';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useInputs from '../../hooks/useInputs';
import ProgressButton from '../../components/ProgressButton';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { User as UserType } from '../../common/types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';

export const jobs: Array<string> = [
  '학생',
  '전업주부',
  '산업',
  '대중교통',
  '물류유통',
  '보건/의료/복지',
  '환경/에너지/수자원',
  '방범/방재',
  '시설물관리',
  '교육',
  '문화/예술',
  '디자인/마케팅/광고',
  '스포츠/관광',
  '근로/고용',
  '주거/건축',
  '경제',
  '행정/공무원',
];

const useStyles = makeStyles({
  root: {
    marginLeft: 56,
    marginRight: 56,
  },
  breadcrumbs: {
    marginBottom: 17,
  },
  saveButton: {
    marginLeft: 10,
  },
  input: {
    marginBottom: 16,
    width: 220,
  },
});

export default function User(): JSX.Element {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = useParams<{ userId: string }>();
  const [isApproving, setIsApproving] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [user, setUser] = useState<UserType>({
    id: 0,
    email: '',
    name: '',
    phone: '',
    gender: '',
    job: '',
    jobDetail: '',
    createdAt: 0,
  });
  const [{ name, phone, gender, job, jobDetail }, onChange, reset] = useInputs({
    name: '',
    phone: '',
    gender: '',
    job: '',
    jobDetail: '',
  });
  const history = useHistory();

  useEffect(() => {
    async function getUser() {
      try {
        const response = await axios.get<UserType>(`/users/${userId}`);
        setUser(response.data);
        reset({
          name: response.data.name,
          phone: response.data.phone,
          gender: response.data.gender,
          job: response.data.job,
          jobDetail: response.data.jobDetail,
        });
      } catch (e) {
        enqueueSnackbar('유저 조회를 실패하였습니다.', { variant: 'error' });
      }
    }
    getUser();
  }, [userId]);

  // const handleClickApprove = async () => {
  //   try {
  //     setIsApproving(true);
  //     await axios.patch<null>(`/users/${user.id}`, {
  //       approved: true,
  //     });
  //     enqueueSnackbar('가입 승인되었습니다.', { variant: 'success' });
  //     setUser((user) => {
  //       return { ...user, approved: true };
  //     });
  //   } catch (e) {
  //     enqueueSnackbar('가입 승인이 실패하였습니다.', { variant: 'error' });
  //   } finally {
  //     setIsApproving(false);
  //   }
  // };
  const handleClickCancel = () => {
    history.push('/users');
  };
  const handleClickSave = async () => {
    try {
      setIsSaving(true);
      await axios.patch<UserType>(`/users/${user.id}`, {
        name,
        phone,
        gender,
        job,
        jobDetail,
      });
      enqueueSnackbar('유저를 수정하였습니다.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('유저 수정을 실패하였습니다.', { variant: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div>
      <Breadcrumbs className={classes.breadcrumbs}>
        <LinkRouter color="inherit" to="/users">
          사용자 관리
        </LinkRouter>
        <Typography color="textPrimary">{user.email}</Typography>
      </Breadcrumbs>
      <div className={classes.root}>
        <Box display="flex" marginBottom="40px">
          {/*<ProgressButton*/}
          {/*  loading={isApproving}*/}
          {/*  variant="contained"*/}
          {/*  color="primary"*/}
          {/*  onClick={handleClickApprove}*/}
          {/*  disabled={user.approved}*/}
          {/*>*/}
          {/*  승인하기*/}
          {/*</ProgressButton>*/}
          <Box flexGrow="1" />
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickCancel}
          >
            취소
          </Button>
          <ProgressButton
            loading={isSaving}
            variant="contained"
            color="primary"
            className={classes.saveButton}
            onClick={handleClickSave}
            disabled={name === ''}
          >
            저장
          </ProgressButton>
        </Box>
        <Box display="flex">
          <Box display="flex" flexDirection="column" marginRight="74px">
            <TextField
              id="email"
              name="email"
              label="ID(Email)"
              InputProps={{
                readOnly: true,
              }}
              value={user.email}
              className={classes.input}
            />
            <TextField
              id="name"
              name="name"
              label="이름"
              value={name}
              onChange={onChange}
              className={classes.input}
            />
            <TextField
              id="phone"
              name="phone"
              label="전화번호"
              value={phone}
              onChange={onChange}
              className={classes.input}
            />
            <FormControl className={classes.input}>
              <InputLabel id="gender-label">성별</InputLabel>
              <Select
                labelId="gender-label"
                id="gender"
                name="gender"
                value={gender}
                onChange={onChange}
              >
                <MenuItem key="M" value="M">
                  남
                </MenuItem>
                <MenuItem key="F" value="F">
                  여
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.input}>
              <InputLabel id="job-label">직업</InputLabel>
              <Select
                labelId="job-label"
                id="job"
                name="job"
                value={job}
                onChange={onChange}
              >
                {jobs.map((job) => (
                  <MenuItem key={job} value={job}>
                    {job}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="jobDetail"
              name="jobDetail"
              label="추가정보"
              value={jobDetail}
              onChange={onChange}
              className={classes.input}
            />
          </Box>
          <Box display="flex" flexDirection="column" marginRight="74px">
            <TextField
              id="createdAt"
              name="createdAt"
              label="등록일시"
              value={new Date(user.createdAt).toLocaleString()}
              disabled
              className={classes.input}
            />
          </Box>
        </Box>
      </div>
    </div>
  );
}
