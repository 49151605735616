import React, { useEffect, useState } from 'react';
import LinkRouter from '../components/LinkRouter';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import useInputs from '../hooks/useInputs';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { User } from '../common/types';
import { useSnackbar } from 'notistack';
import useAuth from '../hooks/useAuth';
import ProgressButton from '../components/ProgressButton';

const useStyles = makeStyles({
  root: {
    marginLeft: 56,
    marginRight: 56,
  },
  breadcrumbs: {
    marginBottom: 56,
  },
  saveButton: {
    marginLeft: 10,
  },
  input: {
    width: 320,
    marginBottom: 16,
  },
});

export default function MyPage(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [myInfo, setMyInfo] = useState<User>({
    id: 0,
    email: '',
    name: '',
    phone: '',
    gender: '',
    job: '',
    jobDetail: '',
    createdAt: 0,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [{ password, newPassword, newPasswordConfirm }, onChange] = useInputs({
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  useEffect(() => {
    async function getMyInfo() {
      try {
        const response = await axios.get<User>(`/users/${auth.user?.id}`);
        setMyInfo(response.data);
      } catch (e) {
        enqueueSnackbar('내 정보 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    getMyInfo();
  }, []);

  const handleClickCancel = () => {
    history.goBack();
  };
  const handleClickSave = async () => {
    try {
      setIsSaving(true);
      await axios.post<null>('/users/change-password', {
        oldPassword: password,
        newPassword,
      });
      enqueueSnackbar('비밀번호를 수정하였습니다.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('비밀번호 수정을 실패하였습니다.', { variant: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div>
      <Breadcrumbs className={classes.breadcrumbs}>
        <LinkRouter color="inherit" to="/my-page">
          마이 페이지
        </LinkRouter>
      </Breadcrumbs>
      <div className={classes.root}>
        <Box display="flex" justifyContent="flex-end" marginBottom="40px">
          <ProgressButton
            loading={isSaving}
            variant="contained"
            color="primary"
            className={classes.saveButton}
            onClick={handleClickSave}
            disabled={
              password === '' ||
              newPassword === '' ||
              !newPassword.match(/^(?=.*[a-z]|.*[A-Z])(?=.*[0-9])(?=.{10,})/) ||
              newPassword !== newPasswordConfirm
            }
          >
            저장
          </ProgressButton>
        </Box>
        <Box display="flex" flexDirection="column">
          <TextField
            id="email"
            name="email"
            label="이메일"
            InputProps={{
              readOnly: true,
            }}
            value={myInfo?.email}
            className={classes.input}
          />
          <TextField
            id="name"
            name="name"
            label="사용자명"
            InputProps={{
              readOnly: true,
            }}
            value={myInfo?.name}
            className={classes.input}
          />
          <TextField
            id="password"
            name="password"
            type="password"
            label="현재 비밀번호"
            value={password}
            onChange={onChange}
            className={classes.input}
          />
          <TextField
            id="newPassword"
            name="newPassword"
            type="password"
            label="새 비밀번호"
            placeholder="비밀번호(영문+숫자+특수문자 10수자이상)"
            error={
              !newPassword.match(/^(?=.*[a-z]|.*[A-Z])(?=.*[0-9])(?=.{10,})/)
            }
            value={newPassword}
            onChange={onChange}
            className={classes.input}
          />
          <TextField
            id="newPasswordConfirm"
            name="newPasswordConfirm"
            type="password"
            label="새 비밀번호 확인"
            value={newPasswordConfirm}
            error={newPasswordConfirm !== newPassword}
            onChange={onChange}
            className={classes.input}
          />
        </Box>
      </div>
    </div>
  );
}
