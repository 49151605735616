import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import LinkRouter from '../../components/LinkRouter';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useInputs from '../../hooks/useInputs';
import { validateEmail } from '../../common/utils';
import ProgressButton from '../../components/ProgressButton';
import axios from 'axios';
import { User } from '../../common/types';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  root: {
    marginLeft: 56,
    marginRight: 56,
  },
  breadcrumbs: {
    marginBottom: 17,
  },
  saveButton: {
    marginLeft: 10,
  },
  input: {
    marginBottom: 16,
    width: 220,
  },
});

export default function AddAdmin(): JSX.Element {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [{ email, name }, onChange] = useInputs({
    email: '',
    name: '',
  });
  const history = useHistory();

  const handleClickCancel = () => {
    history.push('/admins');
  };
  const handleClickSave = async () => {
    try {
      setIsSaving(true);
      await axios.post<User>('/users/create-admin', {
        email,
        name,
      });
      enqueueSnackbar('관리자를 생성하였습니다.', { variant: 'success' });
      history.push('/admins');
    } catch (e) {
      if (e.response.status == 409) {
        enqueueSnackbar('해당 이메일로 이미 관리자가 존재합니다.', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('관리자 생성을 실패하였습니다.', {
          variant: 'error',
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div>
      <Breadcrumbs className={classes.breadcrumbs}>
        <LinkRouter color="inherit" to="/admins">
          관리자 설정
        </LinkRouter>
        <Typography color="textPrimary">관리자 추가</Typography>
      </Breadcrumbs>
      <div className={classes.root}>
        <Box display="flex" justifyContent="flex-end" marginBottom="40px">
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickCancel}
          >
            취소
          </Button>
          <ProgressButton
            loading={isSaving}
            variant="contained"
            color="primary"
            className={classes.saveButton}
            onClick={handleClickSave}
            disabled={email === '' || !validateEmail(email) || name === ''}
          >
            저장
          </ProgressButton>
        </Box>
        <Box display="flex" flexDirection="column" marginRight="74px">
          <TextField
            id="email"
            name="email"
            type="email"
            label="ID(이메일)"
            value={email}
            onChange={onChange}
            error={email && !validateEmail(email)}
            className={classes.input}
          />
          <TextField
            id="name"
            name="name"
            label="이름"
            value={name}
            onChange={onChange}
            className={classes.input}
          />
        </Box>
      </div>
    </div>
  );
}
