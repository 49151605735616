import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import useInputs from '../../hooks/useInputs';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import LinkRouter from '../../components/LinkRouter';
import IconButton from '@material-ui/core/IconButton';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { User } from '../../common/types';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  breadcrumbs: {
    marginBottom: 56,
  },
  searchInput: {
    width: 220,
  },
  table: {
    minWidth: 650,
  },
});

export default function Users(): JSX.Element {
  const classes = useStyles();
  const [users, setUsers] = useState<User[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const [{ query }, onChange] = useInputs({
    query: '',
  });
  const count = useMemo(
    () =>
      users.filter(
        (user) => user.email.includes(query) || user.name.includes(query),
      ).length,
    [users, query],
  );
  const history = useHistory();

  useEffect(() => {
    setPage(0);
  }, [query]);

  useEffect(() => {
    async function getUsers() {
      try {
        const response = await axios.get<User[]>('/users', {
          params: {
            admin: false,
          },
        });
        setUsers(response.data);
      } catch (e) {
        enqueueSnackbar('유저 목록 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    getUsers();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickEdit = (id: number) => () => {
    history.push(`/users/${id}`);
  };

  const handleClickDelete = (id: number) => async () => {
    try {
      if (confirm('정말로 삭제하시겠습니까?')) {
        await axios.delete<null>(`/users/${id}`);
        enqueueSnackbar('유저를 삭제하였습니다.', { variant: 'success' });
        setUsers(users.filter((user) => user.id !== id));
      }
    } catch (e) {
      enqueueSnackbar('유저 삭제를 실패하였습니다.', { variant: 'error' });
    }
  };

  return (
    <div>
      <Breadcrumbs className={classes.breadcrumbs}>
        <LinkRouter color="inherit" to="/users">
          사용자 관리
        </LinkRouter>
      </Breadcrumbs>
      <Box display="flex" flexDirection="row-reverse" marginBottom="12px">
        <TextField
          id="search"
          name="query"
          label="Search"
          type="search"
          variant="outlined"
          size="small"
          margin="dense"
          className={classes.searchInput}
          value={query}
          onChange={onChange}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID(Email)</TableCell>
              <TableCell>이름</TableCell>
              <TableCell>등록일시</TableCell>
              <TableCell>관리</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter(
                (user) =>
                  user.email.includes(query) || user.name.includes(query),
              )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow key={user.id}>
                  <TableCell component="th" scope="row">
                    {user.email}
                  </TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>
                    {new Date(user.createdAt).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={handleClickEdit(user.id)}>
                      <EditRoundedIcon />
                    </IconButton>
                    <IconButton onClick={handleClickDelete(user.id)}>
                      <DeleteRoundedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
