import React from 'react';
import LinkRouter from '../../components/LinkRouter';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import QuestInfo from './QuestInfo';

const useStyles = makeStyles({
  root: {
    marginLeft: 56,
    marginRight: 56,
    display: 'flex',
    flexDirection: 'column',
  },
  breadcrumbs: {
    marginBottom: 17,
    color: '#050709',
  },
});

export default function AddQuest(): JSX.Element {
  const classes = useStyles();

  return (
    <div>
      <Breadcrumbs className={classes.breadcrumbs}>
        <LinkRouter color="inherit" to="/quests">
          참여활동 관리
        </LinkRouter>
        <Typography color="textPrimary">참여활동 추가</Typography>
      </Breadcrumbs>
      <div className={classes.root}>
        <QuestInfo />
      </div>
    </div>
  );
}
