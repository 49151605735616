import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import LinkRouter from '../../components/LinkRouter';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import axios from 'axios';
import { Project } from '../../common/types';
import { useSnackbar } from 'notistack';
import SearchInput from '../../components/SearchInput';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../components/TabPanel';

const useStyles = makeStyles({
  breadcrumbs: {
    marginBottom: 56,
    fontSize: 20,
    color: '#050709',
  },
  addProject: {
    height: 32,
  },
  cardList: {
    marginLeft: -12,
    marginRight: -12,
    display: 'flex',
    flexWrap: 'wrap',
    '&::after': {
      content: '""',
      flex: 'auto',
    },
  },
  card: {
    minWidth: 170,
    width: 'calc(25% - 24px)',
    marginBottom: 25,
    marginLeft: 12,
    marginRight: 12,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    marginLeft: 16,
    marginRight: 16,
    borderRadius: 8,
  },
});

function CardList({
  projects,
  titleQuery,
  onEdit,
  onDelete,
}: {
  projects: Project[];
  titleQuery: string;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}): JSX.Element {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [titleQuery]);

  return (
    <div>
      <div className={classes.cardList}>
        {projects
          .filter((project) => project.title.includes(titleQuery))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((project) => (
            <Card className={classes.card} key={project.id}>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  marginBottom="8px"
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {project.subcategory}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {new Date(project.createdAt).toLocaleDateString()}
                  </Typography>
                </Box>
                <Typography variant="body1" color="textPrimary" component="p">
                  {project.title}
                </Typography>
              </CardContent>
              <CardMedia
                className={classes.media}
                image={
                  project.thumbnailUrl.includes('https://')
                    ? project.thumbnailUrl
                    : `${process.env.REACT_APP_BASE_URL}${project.thumbnailUrl}`
                }
                title="Paella dish"
              />
              <CardActions disableSpacing style={{ float: 'right' }}>
                <Button color="primary" onClick={() => onEdit(project.id)}>
                  수정
                </Button>
                <Button color="primary" onClick={() => onDelete(project.id)}>
                  삭제
                </Button>
              </CardActions>
            </Card>
          ))}
      </div>
      <TablePagination
        rowsPerPageOptions={[8, 16, 32]}
        component="div"
        count={projects.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
export default function Projects(): JSX.Element {
  const classes = useStyles();
  const [projects, setProjects] = useState<Project[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [titleQuery, setTitleQuery] = useState('');
  const [tabValue, setTabValue] = React.useState(0);

  const history = useHistory();

  useEffect(() => {
    async function getProjects() {
      try {
        const response = await axios.get<Project[]>('/projects');
        setProjects(response.data);
      } catch (e) {
        enqueueSnackbar('프로젝트 목록 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    getProjects();
  }, []);

  const handleClickAdd = () => {
    history.push(`/projects/add`);
  };
  const handleChangeTab = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setTabValue(newValue);
  };

  const handleClickEdit = (id: number) => {
    history.push(`/projects/${id}`);
  };

  const handleClickDelete = async (id: number) => {
    try {
      if (confirm('정말로 삭제하시겠습니까?')) {
        await axios.delete<null>(`/projects/${id}`);
        enqueueSnackbar('프로젝트를 삭제하였습니다.', { variant: 'success' });
        setProjects(projects.filter((project) => project.id !== id));
      }
    } catch (e) {
      enqueueSnackbar('프로젝트 삭제를 실패하였습니다.', { variant: 'error' });
    }
  };
  return (
    <div>
      <SearchInput onSearch={(text) => setTitleQuery(text)} />
      <Breadcrumbs className={classes.breadcrumbs}>
        <LinkRouter color="inherit" to="/projects">
          알아보기 관리
        </LinkRouter>
      </Breadcrumbs>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="24px"
      >
        <div>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="전체" />
            <Tab label="새소식" />
            <Tab label="포항스마트시티" />
            <Tab label="포항시티랩" />
          </Tabs>
        </div>
        <Button
          variant="contained"
          size="small"
          color="primary"
          startIcon={<AddRoundedIcon />}
          className={classes.addProject}
          onClick={handleClickAdd}
        >
          알아보기 추가
        </Button>
      </Box>
      <TabPanel index={0} value={tabValue}>
        <CardList
          projects={projects}
          titleQuery={titleQuery}
          onEdit={handleClickEdit}
          onDelete={handleClickDelete}
        />
      </TabPanel>
      <TabPanel index={1} value={tabValue}>
        <CardList
          projects={projects.filter((p) => p.category.value === 'news')}
          titleQuery={titleQuery}
          onEdit={handleClickEdit}
          onDelete={handleClickDelete}
        />
      </TabPanel>
      <TabPanel index={2} value={tabValue}>
        <CardList
          projects={projects.filter((p) => p.category.value === 'smartcity')}
          titleQuery={titleQuery}
          onEdit={handleClickEdit}
          onDelete={handleClickDelete}
        />
      </TabPanel>
      <TabPanel index={3} value={tabValue}>
        <CardList
          projects={projects.filter((p) => p.category.value === 'citylab')}
          titleQuery={titleQuery}
          onEdit={handleClickEdit}
          onDelete={handleClickDelete}
        />
      </TabPanel>
    </div>
  );
}
