import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
} from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import HeightIcon from '@material-ui/icons/Height';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import { Quest, QuestInvitation } from '../../common/types';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import ProgressButton from '../../components/ProgressButton';
import { ExcelIcon } from '../../components/Icons';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    color: '#292A33',
    fontWeight: 500,
  },
  subTitle: {
    color: '#777B8C',
    fontSize: 14,
  },
  table: {
    width: '100%',
    overflowY: 'scroll',
  },
  sortableCell: {
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  rsvpContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  greenCircle: {
    display: 'inline-block',
    width: 8,
    height: 8,
    marginRight: 4,
    borderRadius: '100%',
    backgroundColor: '#00C133',
  },
  redCircle: {
    display: 'inline-block',
    width: 8,
    height: 8,
    marginRight: 4,
    borderRadius: '100%',
    backgroundColor: '#FF0000',
  },
  excelButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 4,
    color: '#4E80FF',
    fontSize: 14,
    marginLeft: 20,
    cursor: 'pointer',
  },
});

interface Props {
  quest: Quest | null;
  open: boolean;
  onClose: () => void;
}
export const AttendanceDialog = ({ quest, open, onClose }: Props) => {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [questInvitations, setQuestInvitations] = useState<QuestInvitation[]>(
    [],
  );

  const [nameSort, setNameSort] = useState<'asc' | 'desc'>('asc');
  const [rsvpSort, setRsvpSort] = useState<'will' | 'not'>('will');
  const [currentSort, setCurrentSort] = useState<'name' | 'rsvp'>('name');

  const handleUpdateAttendance = async (id: number, attended: boolean) => {
    setQuestInvitations((prevState) =>
      prevState.map((invitation) =>
        invitation.id === id ? { ...invitation, attended } : invitation,
      ),
    );
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await axios.post(`/quests/${quest?.id}/save-attendances`, {
        attendances: questInvitations.map((invitation) => ({
          id: invitation.id,
          attended: invitation.attended,
        })),
      });
      enqueueSnackbar('참여자 회신명단을 저장하였습니다.', {
        variant: 'success',
      });
      onClose();
    } catch (e) {
      enqueueSnackbar('참여자 회신명단 저장을 실패하였습니다.', {
        variant: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleDownloadExcel = async () => {
    try {
      const response = await axios.get(
        `/quests/${quest?.id}/invitations/download-excel`,
        {
          responseType: 'blob',
        },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'attendance.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      enqueueSnackbar('엑셀 다운로드를 실패하였습니다.', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    async function getQuestInvitations() {
      try {
        const response = await axios.get<QuestInvitation[]>(
          `/quests/${quest?.id}/invitations`,
        );
        setQuestInvitations(response.data);
      } catch (e) {
        enqueueSnackbar('참여자 회신명단 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    if (open && quest) {
      getQuestInvitations();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle className={classes.title}>참여자 회신 명단</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div className={classes.subTitle}>
            {quest?.title} | 총 {questInvitations.length}명, 참석가능{' '}
            {questInvitations.filter((q) => q.rsvpState === 'WILL').length}명
          </div>
          <TableContainer component={Paper} style={{ maxHeight: '70vh' }}>
            <Table
              className={classes.table}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell>번호</TableCell>
                  <TableCell
                    onClick={() => {
                      setCurrentSort('name');
                      setNameSort((prevState) =>
                        prevState === 'asc' ? 'desc' : 'asc',
                      );
                    }}
                  >
                    <div className={classes.sortableCell}>
                      이름 <HeightIcon color="disabled" />
                    </div>
                  </TableCell>
                  <TableCell>이메일</TableCell>
                  <TableCell
                    onClick={() => {
                      setCurrentSort('rsvp');
                      setRsvpSort((prevState) =>
                        prevState === 'will' ? 'not' : 'will',
                      );
                    }}
                  >
                    <div className={classes.sortableCell}>
                      참여회신여부 <HeightIcon color="disabled" />
                    </div>
                  </TableCell>
                  <TableCell align="center">참여</TableCell>
                  <TableCell align="center">미참여</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {questInvitations
                  .sort((a, b) => {
                    if (currentSort === 'name') {
                      return nameSort === 'asc'
                        ? a.user.name.localeCompare(b.user.name)
                        : b.user.name.localeCompare(a.user.name);
                    } else {
                      return rsvpSort === 'will'
                        ? a.rsvpState === 'WILL'
                          ? -1
                          : 1
                        : b.rsvpState === 'WILL'
                        ? -1
                        : 1;
                    }
                  })
                  .map((invitation, idx) => (
                    <TableRow key={invitation.id}>
                      <TableCell component="th" scope="row">
                        {idx + 1}
                      </TableCell>
                      <TableCell>{invitation.user.name}</TableCell>
                      <TableCell>{invitation.user.email}</TableCell>
                      <TableCell>
                        {invitation.rsvpState === 'WILL' && (
                          <div className={classes.rsvpContainer}>
                            <div className={classes.greenCircle}></div> 참석가능
                          </div>
                        )}
                        {invitation.rsvpState === 'NOT' && (
                          <div className={classes.rsvpContainer}>
                            <div className={classes.redCircle}></div> 참석불가
                          </div>
                        )}
                        {invitation.rsvpState === 'NONE' && '-'}
                      </TableCell>
                      <TableCell align="center">
                        <Radio
                          checked={invitation.attended}
                          onChange={() =>
                            handleUpdateAttendance(invitation.id, true)
                          }
                          value="attended"
                          name={`attendance-${invitation.id}`}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Radio
                          checked={!invitation.attended}
                          onChange={() =>
                            handleUpdateAttendance(invitation.id, false)
                          }
                          value="attended"
                          name={`attendance-${invitation.id}`}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContentText>
      </DialogContent>
      {questInvitations.length > 0 && (
        <div
          className={classes.excelButtonContainer}
          onClick={handleDownloadExcel}
        >
          <ExcelIcon /> 엑셀 다운로드
        </div>
      )}
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          닫기
        </Button>
        <ProgressButton
          loading={isSaving}
          variant="contained"
          color="primary"
          disabled={questInvitations.length === 0}
          onClick={handleSave}
        >
          출석체크 저장
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};
