import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TotalStats } from '../common/types';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import HomeBackgroundImage from '../images/HomeBackground.png';
import {
  CamIcon,
  MeetIcon,
  MetaverseIcon,
  PollIcon,
} from '../components/Icons';
import SearchInput from '../components/SearchInput';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F0F4FF',
    height: '100vh',
    margin: -theme.spacing(3),
    marginLeft: -(theme.spacing(3) + 5),
    paddingLeft: 43 + 5,
    paddingTop: 54,
    paddingRight: 54,
    boxSizing: 'border-box',
    backgroundImage: `url(${HomeBackgroundImage})`,
    backgroundPosition: 'bottom',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  title: {
    fontSize: 28,
  },
  description: {
    fontSize: 14,
    marginTop: 4,
  },
  sectionTitle: {
    fontSize: 16,
  },
  link: {
    fontSize: 12,
    fontWeight: 300,
  },
  statsBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statsContainer: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '1rem',
    width: '100%',
    maxWidth: '980px',
    marginTop: '40px',
    height: '80vh',
  },
  statsUserContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '1rem',
    color: '#4E80FF',
    fontSize: '20px',
    fontWeight: 'bold',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    padding: '1.5rem',
    width: '29%',
  },
  userTitle: {
    marginTop: '0px',
  },
  userCount: {
    marginTop: '2px',
    fontSize: '3.75rem',
  },
  userCountSubText: {
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
  },
  accVisitContainer: {
    width: 'fit-content',
    marginTop: '0.5rem',
    backgroundColor: '#E3ECFF',
    borderRadius: '9999px',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '0.25rem 0.75rem',
  },
  accVisitText: {
    fontWeight: 'bold',
  },
  jobStatsContainer: {
    display: 'flex',
    overflowY: 'scroll',
    flexDirection: 'column',
    rowGap: '1rem',
    marginTop: '3rem',
  },
  jobStatsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  jobStatsDotBg: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '0.25rem',
    top: '50%',
    backgroundImage: "url('/dot_bg.png')",
    backgroundRepeat: 'repeat-x',
    backgroundSize: 'contain',
  },
  jobName: {
    backgroundColor: 'white',
    zIndex: 10,
    fontSize: '16px',
  },
  jobTitle: {
    color: '#292A33',
  },
  jobCount: {
    color: '#777B8C',
  },
  projectStatsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    rowGap: '1rem',
  },
  projectStatsCardContainer: {
    display: 'flex',
    height: '16.6%',
    columnGap: '1rem',
  },
  projectStatsCard: {
    display: 'flex',
    position: 'relative',
    width: '50%',
    height: '100%',
    backgroundColor: '#9C4EFF',
    overflow: 'hidden',
    borderRadius: '1rem',
  },
  questStatsCard: {
    display: 'flex',
    position: 'relative',
    width: '50%',
    height: '100%',
    backgroundColor: '#FF7A00',
    overflow: 'hidden',
    borderRadius: '1rem',
  },
  cardImg: {
    position: 'absolute',
    right: '-0.5rem',
    bottom: '-0.5rem',
  },
  cardLeftDiv: {
    display: 'flex',
    width: '40%',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    paddingLeft: '1rem',
    color: 'white',
    fontWeight: 'bold',
  },
  cardLeftDivTitle: {
    fontSize: '20px',
  },
  cardLeftDivCount: {
    fontSize: '48px',
  },
  cardLeftDivCountSub: {
    fontSize: '32px',
  },
  cardRightDivText: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  cardRightDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    marginLeft: '1.5rem',
    paddingTop: '1rem',
    color: 'white',
    fontWeight: 'bold',
  },
  cardRightDiv2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    paddingTop: '1rem',
    marginLeft: '0.5rem',
    color: 'white',
    fontWeight: 'bold',
  },
  projectStatsTable: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'scroll',
    height: '83%',
    backgroundColor: 'white',
    borderRadius: '1rem',
    fontSize: '20px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
  },
  projectStatsTableHeader: {
    minWidth: '600px',
    display: 'flex',
    height: '46px',
    lineHeight: '46px',
    paddingLeft: '16px',
    borderBottom: '1px solid #D5D7E5',
    color: '#777B8C',
    fontSize: '14px',
  },
  projectStatsTableRow: {
    minWidth: '600px',
    display: 'flex',
    minHeight: '36px',
    paddingTop: '4px',
    paddingBottom: '4px',
    alignItems: 'center',
    borderBottom: '1px solid #D5D7E5',
    color: '#292A33',
    paddingLeft: '16px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  columnProjectName: {
    width: '42%',
    display: 'flex',
    alignItems: 'center',
  },
  columnTotalQuests: {
    width: '17%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  columnTotalQuestsColored: {
    width: '17%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FF7A00',
  },
  columnIcons: {
    width: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function HomePage(): JSX.Element {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [totalStats, setTotalStats] = useState<TotalStats | null>(null);
  const [jobStats, setJobStats] = useState<
    {
      jobName: string;
      jobCount: number;
    }[]
  >([]);

  useEffect(() => {
    async function getCurrentStatus() {
      try {
        const response = await axios.get<TotalStats>('/get-total-stats');
        const nullJobs = response.data.userStats.jobStats.find(
          (job) => job.jobName === 'N/A',
        );
        let totalJobs = response.data.userStats.jobStats
          .filter((job) => job.jobName !== 'N/A')
          .sort((a, b) => (a.jobCount > b.jobCount ? -1 : 1));
        if (totalJobs.length > 10) {
          console.log(nullJobs);
          const etcJobs = {
            jobName:
              totalJobs[10].jobName + ' 외 ' + (totalJobs.length - 10) + '개',
            jobCount:
              totalJobs.slice(10).reduce((a, b) => a + b.jobCount, 0) +
              (nullJobs ? nullJobs.jobCount : 0),
          };
          totalJobs = totalJobs.slice(0, 10);
          totalJobs.push(etcJobs);
        }
        setTotalStats(response.data);
        setJobStats(totalJobs);
      } catch (e) {
        enqueueSnackbar('대시보드 정보 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    getCurrentStatus();
  }, []);

  return (
    <div className={classes.root}>
      <SearchInput onSearch={(text: string) => console.log(text)} />
      <Typography color="textPrimary" className={classes.title}>
        오늘의 시티랩 포항,
      </Typography>
      <Typography color="textSecondary" className={classes.description}>
        이 페이지는 관리자 페이지로 시티랩 앱운영에 대한 관리를 위한
        페이지입니다.
      </Typography>
      <div className={classes.statsBox}>
        <div className={classes.statsContainer}>
          <div className={classes.statsUserContainer}>
            {totalStats && (
              <>
                <div className={classes.userTitle}>시민</div>
                <div className={classes.userCount}>
                  {totalStats.userStats.jobStats.reduce(
                    (a, b) => a + b.jobCount,
                    0,
                  )}
                  <span className={classes.userCountSubText}>명</span>
                </div>
                <div className={classes.accVisitContainer}>
                  누적 방문자 수{' '}
                  <span className={classes.accVisitText}>
                    {totalStats.userStats.totalVisit}명
                  </span>
                </div>
                <div className={classes.jobStatsContainer}>
                  {jobStats.map((job, idx) => (
                    <div key={idx} className={classes.jobStatsRow}>
                      <div className={classes.jobStatsDotBg}></div>
                      <div className={classes.jobName}>{job.jobName}</div>
                      <div className={classes.jobName}>
                        <span className={classes.jobTitle}>{job.jobCount}</span>
                        <span className="text-">명</span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className={classes.projectStatsContainer}>
            <div className={classes.projectStatsCardContainer}>
              <div className={classes.projectStatsCard}>
                <img
                  src="/project_icon.png"
                  alt="project_icon"
                  width={168}
                  height={128}
                  className={classes.cardImg}
                />
                {totalStats && (
                  <>
                    <div className={classes.cardLeftDiv}>
                      <div className={classes.cardLeftDivTitle}>프로젝트</div>
                      <div className={classes.cardLeftDivCount}>
                        {totalStats.projectStats.length}
                        <span className={classes.cardLeftDivCountSub}>개</span>
                      </div>
                    </div>
                    <div className={classes.cardRightDiv}>
                      <div className={classes.cardRightDivText}>본사업</div>
                      <div className={classes.cardLeftDivTitle}>
                        {
                          totalStats.projectStats.filter((p) =>
                            p.subcategory.includes('본사업'),
                          ).length
                        }
                      </div>
                    </div>
                    <div className={classes.cardRightDiv2}>
                      <div className={classes.cardRightDivText}>예비사업</div>
                      <div className={classes.cardLeftDivTitle}>
                        {
                          totalStats.projectStats.filter((p) =>
                            p.subcategory.includes('예비사업'),
                          ).length
                        }
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className={classes.questStatsCard}>
                <img
                  src="/quest_icon.png"
                  alt="project_icon"
                  width={168}
                  height={128}
                  className={classes.cardImg}
                />
                {totalStats && (
                  <>
                    <div className={classes.cardLeftDiv}>
                      <div className={classes.cardLeftDivTitle}>참여활동</div>
                      <div className={classes.cardLeftDivCount}>
                        {totalStats.projectStats.reduce(
                          (a, b) =>
                            a +
                            b.questCount.numOffline +
                            b.questCount.numOnline +
                            b.questCount.numMetaverse +
                            b.questCount.numPoll,
                          0,
                        )}
                        <span className={classes.cardLeftDivCountSub}>개</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={classes.projectStatsTable}>
              <div className={classes.projectStatsTableHeader}>
                <span className={classes.columnProjectName}>프로젝트 명</span>
                <span className={classes.columnTotalQuests}>총 참여활동수</span>
                <span className={classes.columnIcons}>
                  <PollIcon color="#909090" />
                </span>
                <span className={classes.columnIcons}>
                  <CamIcon color="#909090" />
                </span>
                <span className={classes.columnIcons}>
                  <MeetIcon color="#909090" />
                </span>
                <span className={classes.columnIcons}>
                  <MetaverseIcon color="#909090" />
                </span>
              </div>
              {totalStats &&
                totalStats.projectStats.map((project, idx) => (
                  <div
                    className={classes.projectStatsTableRow}
                    key={`row-${idx}`}
                  >
                    <span className={classes.columnProjectName}>
                      {project.title}
                    </span>
                    <span className={classes.columnTotalQuestsColored}>
                      {project.questCount.numPoll +
                        project.questCount.numOnline +
                        project.questCount.numOffline +
                        project.questCount.numMetaverse}
                      개
                    </span>
                    <span className={classes.columnIcons}>
                      {project.questCount.numPoll}
                    </span>
                    <span className={classes.columnIcons}>
                      {project.questCount.numOnline}
                    </span>
                    <span className={classes.columnIcons}>
                      {project.questCount.numOffline}
                    </span>
                    <span className={classes.columnIcons}>
                      {project.questCount.numMetaverse}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
