import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import useInputs from '../../hooks/useInputs';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { Project, Quest, QuestCategory, User } from '../../common/types';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import ProgressButton from '../../components/ProgressButton';
import { Checkbox, FormGroup, FormLabel, Link } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { parseDate, parseDateTime, parseTime } from '../../common/utils';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import { useDropzone } from 'react-dropzone';
import { read, utils } from 'xlsx';
import { ClockIcon } from '../../components/Icons';

const useStyles = makeStyles({
  saveButton: {
    marginLeft: 10,
  },
  input: {
    marginBottom: 16,
    width: '100%',
  },
  fullWidthInput: {
    marginBottom: 16,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  inviteesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 8,
    columnGap: 24,
    border: '1px solid #D5D7E5',
    borderRadius: 8,
    padding: 24,
    maxHeight: 230,
    overflowY: 'scroll',
    marginTop: 8,
    minHeight: 230,
  },
  usersContainer: {
    display: 'flex',
    width: '87%',
    flexWrap: 'wrap',
    rowGap: 8,
    columnGap: 24,
    border: '1px solid #D5D7E5',
    borderRadius: 8,
    padding: 24,
    maxHeight: 230,
    overflowY: 'scroll',
    marginTop: 8,
    minHeight: 230,
  },
  inviteeContainer: {
    display: 'flex',
    minWidth: 210,
    height: 40,
    alignItems: 'center',
  },
  invitee: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 182,
    height: 40,
    cursor: 'pointer',
  },
  inviteeName: {
    fontSize: 14,
    fontWeight: 700,
    color: '#292A33',
  },
  inviteeEmail: {
    fontSize: 14,
    fontWeight: 500,
    color: '#777B8C',
  },
  deleteInvitation: {
    marginLeft: 2,
    cursor: 'pointer',
  },
  searchInput: {
    width: 'calc(100% - 122px)',
    marginTop: 8,
  },
  dropzone: {
    width: 130,
    height: 45,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#3740DD',
    borderStyle: 'solid',
    color: '#3740DD',
    fontSize: 14,
    cursor: 'pointer',
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
    marginTop: 16,
  },
  editorContainerTitle: {
    marginBottom: 4,
    fontSize: 14,
    color: '#696969',
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 4,
    alignItems: 'center',
  },
  timeGap: {
    marginLeft: 4,
    marginRight: 4,
  },
});

interface QuestInfoProps {
  quest?: Quest;
  setQuest?: (quest: Quest) => void;
}

export type UserInvitation = {
  email: string;
  member: boolean;
  name: string;
};

interface UserInvitationExcelFormat {
  No: number;
  Name: string;
  Email: string;
}

export default function QuestInfo({
  quest,
  setQuest,
}: QuestInfoProps): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [categories, setCategories] = useState<QuestCategory[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);
  const [projects, setProjects] = useState<{ id: number; title: string }[]>([]);
  const [startAt, setStartAt] = useState<Date | null>(null);
  const [endAt, setEndAt] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<{ hour: string; min: string }>({
    hour: '09',
    min: '00',
  });
  const [endTime, setEndTime] = useState<{ hour: string; min: string }>({
    hour: '18',
    min: '00',
  });
  const [invitations, setInvitations] = useState<UserInvitation[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [searchUserText, setSearchUserText] = useState('');
  const [openUserList, setOpenUserList] = useState(false);
  const [openPublishConfirm, setOpenPublishConfirm] = useState(false);
  const [resourceLoaded, setResourceLoaded] = useState(false);

  const [
    {
      title,
      editorContents,
      projectId,
      link,
      resultLink,
      categoryId,
      place,
      placeUrl,
      repeatable,
      published,
      allTarget,
    },
    onChange,
    reset,
  ] = useInputs({
    title: '',
    editorContents: '',
    projectId: 0,
    categoryId: 0,
    link: '',
    resultLink: '',
    allTarget: true,
    published: false,
    repeatable: false,
    place: '',
    placeUrl: '',
  });
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.xlsx, .xls, .csv, .tsv',
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        return;
      }
      const data = await acceptedFiles[0].arrayBuffer();
      handleUploadExcel(data);
    },
  });
  const editorRef = useRef<any>(null);
  useEffect(() => {
    if (categoryId !== categories.find((c) => c.value === 'poll')?.id) {
      setEndAt(startAt);
    } else {
      setStartTime({ hour: '09', min: '00' });
      setEndTime({ hour: '18', min: '00' });
    }

    if (categoryId === categories.find((c) => c.value === 'offline')?.id) {
      onChange({
        target: {
          name: 'link',
          value: '',
        },
      });
      onChange({
        target: {
          name: 'resultLink',
          value: '',
        },
      });
    } else {
      onChange({
        target: {
          name: 'place',
          value: '',
        },
      });
      onChange({
        target: {
          name: 'placeUrl',
          value: '',
        },
      });
    }
  }, [categoryId]);

  useEffect(() => {
    if (allTarget) {
      setInvitations([]);
    }
  }, [allTarget]);
  useEffect(() => {
    if (searchUserText !== '') {
      setOpenUserList(true);
    }
  }, [searchUserText]);

  useEffect(() => {
    async function getCategories() {
      try {
        const response = await axios.get<QuestCategory[]>('/qcategories');
        setCategories(response.data);
        if (!quest) {
          onChange({
            target: {
              name: 'categoryId',
              value: response.data[0].id,
            },
          });
        }
      } catch (e) {
        enqueueSnackbar('참여활동 유형목록 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    async function getProjects() {
      try {
        const response = await axios.get<Project[]>('/projects');
        setProjects(response.data);
      } catch (e) {
        enqueueSnackbar('프로젝트 목록 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    async function getResources() {
      await getCategories();
      await getProjects();
      await getUsers();
      setResourceLoaded(true);
    }
    getResources();
  }, []);

  useEffect(() => {
    async function getInvitations() {
      try {
        const response = await axios.get<UserInvitation[]>(
          `/quests/${quest?.id}/invitees`,
        );
        setInvitations(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    if (quest && resourceLoaded) {
      reset({
        title: quest.title,
        editorContents: quest.editorContents,
        projectId: quest.project.id,
        categoryId: quest.category.id,
        link: quest.link,
        resultLink: quest.resultLink,
        allTarget: quest.allTarget,
        published: quest.published,
        repeatable: quest.repeatable,
        place: quest.place,
        placeUrl: quest.placeUrl,
      });
      setStartAt(new Date(quest.startAt));
      setEndAt(new Date(quest.endAt));
      if (quest.category.value !== 'poll') {
        setStartTime({
          hour: quest.startAt.split(' ')[1].split(':')[0],
          min: quest.startAt.split(' ')[1].split(':')[1],
        });
        setEndTime({
          hour: quest.endAt.split(' ')[1].split(':')[0],
          min: quest.endAt.split(' ')[1].split(':')[1],
        });
      }

      getInvitations();
    }
  }, [resourceLoaded, quest]);

  useEffect(() => {
    if (startAt) {
      if (categoryId !== categories.find((c) => c.value === 'poll')?.id) {
        setEndAt(startAt);
      }
    }
  }, [startAt]);

  const getUsers = async () => {
    try {
      const response = await axios.get<User[]>('/users');
      setUsers(response.data);
    } catch (e) {
      enqueueSnackbar('사용자 목록 조회를 실패하였습니다.', {
        variant: 'error',
      });
    }
  };

  const handleClickCancel = () => {
    history.goBack();
  };
  const handleClickSave = async () => {
    setIsSaving(true);
    const pollCategory = categories.find((c) => c.value === 'poll');
    if (quest) {
      if (!startAt || !endAt) {
        enqueueSnackbar('시작/종료 일자를 입력해주세요.', {
          variant: 'error',
        });
        setIsSaving(false);
        return;
      }
      try {
        const response = await axios.patch<Quest>(`/quests/${quest.id}`, {
          title,
          editorContents: editorRef.current.getInstance().getHTML(),
          categoryId,
          projectId,
          startAt:
            categoryId === pollCategory!.id
              ? `${parseDate(startAt, '-')} 00:00:00`
              : `${parseDate(startAt, '-')} ${startTime.hour}:${
                  startTime.min
                }:00`,
          endAt:
            categoryId === pollCategory!.id
              ? `${parseDate(endAt, '-')} 23:59:00`
              : `${parseDate(endAt, '-')} ${endTime.hour}:${endTime.min}:00`,
          link,
          place,
          placeUrl,
          repeatable,
          resultLink,
          allTarget,
          invitations: invitations.map((i) => {
            return { email: i.email, member: i.member };
          }),
        });
        enqueueSnackbar('참여활동을 수정하였습니다.', { variant: 'success' });
        setQuest?.(response.data);
        location.reload();
      } catch (e) {
        enqueueSnackbar('참여활동 수정을 실패하였습니다.', {
          variant: 'error',
        });
      } finally {
        setIsSaving(false);
      }
    } else {
      try {
        if (!startAt || !endAt) {
          enqueueSnackbar('시작/종료 일자를 입력해주세요.', {
            variant: 'error',
          });
          return;
        }
        const response = await axios.post<Quest>(`/quests`, {
          title,
          editorContents: editorRef.current.getInstance().getHTML(),
          projectId,
          categoryId,
          repeatable,
          published: false,
          allTarget,
          startAt:
            categoryId === pollCategory!.id
              ? `${parseDate(startAt, '-')} 00:00:00`
              : `${parseDate(startAt, '-')} ${startTime.hour}:${
                  startTime.min
                }:00`,
          endAt:
            categoryId === pollCategory!.id
              ? `${parseDate(endAt, '-')} 23:59:00`
              : `${parseDate(endAt, '-')} ${endTime.hour}:${endTime.min}:00`,
          link,
          resultLink,
          place,
          placeUrl,
          invitations: invitations.map((i) => {
            return { email: i.email, member: i.member };
          }),
        });
        enqueueSnackbar('참여활동을 생성하였습니다.', { variant: 'success' });
        history.replace(`/quests`);
      } catch (e) {
        enqueueSnackbar('참여활동 생성을 실패하였습니다.', {
          variant: 'error',
        });
      } finally {
        setIsSaving(false);
      }
    }
    setIsSaving(false);
  };

  const handleClickPublish = async () => {
    setIsSaving(true);
    if (quest) {
      const pollCategory = categories.find((c) => c.value === 'poll');
      // save first
      if (!startAt || !endAt) {
        enqueueSnackbar('시작/종료 일자를 입력해주세요.', {
          variant: 'error',
        });
        setIsSaving(false);
        return;
      }
      try {
        const response = await axios.patch<Quest>(`/quests/${quest.id}`, {
          title,
          editorContents: editorRef.current.getInstance().getHTML(),
          categoryId,
          projectId,
          startAt:
            categoryId === pollCategory!.id
              ? `${parseDate(startAt, '-')} 00:00:00`
              : `${parseDate(startAt, '-')} ${startTime.hour}:${
                  startTime.min
                }:00`,
          endAt:
            categoryId === pollCategory!.id
              ? `${parseDate(endAt, '-')} 23:59:00`
              : `${parseDate(endAt, '-')} ${endTime.hour}:${endTime.min}:00`,
          link,
          place,
          placeUrl,
          repeatable,
          resultLink,
          allTarget,
          invitations: invitations.map((i) => {
            return { email: i.email, member: i.member };
          }),
        });
        // and publish
        await axios.post<Quest>(`/quests/${quest.id}/publish`);
        enqueueSnackbar('참여활동을 게시하였습니다.', { variant: 'success' });
        history.replace(`/quests`);
      } catch (e) {
        enqueueSnackbar('참여활동 게시를 실패하였습니다.', {
          variant: 'error',
        });
      } finally {
        setIsSaving(false);
      }
    } else {
      const pollCategory = categories.find((c) => c.value === 'poll');
      if (!startAt || !endAt) {
        enqueueSnackbar('시작/종료 일자를 입력해주세요.', {
          variant: 'error',
        });
        return;
      }
      const response = await axios.post<Quest>(`/quests`, {
        title,
        editorContents: editorRef.current.getInstance().getHTML(),
        projectId,
        categoryId,
        repeatable,
        published: true,
        allTarget,
        startAt:
          categoryId === pollCategory!.id
            ? `${parseDate(startAt, '-')} 00:00:00`
            : `${parseDate(startAt, '-')} ${startTime.hour}:${
                startTime.min
              }:00`,
        endAt:
          categoryId === pollCategory!.id
            ? `${parseDate(endAt, '-')} 23:59:00`
            : `${parseDate(endAt, '-')} ${endTime.hour}:${endTime.min}:00`,
        link,
        resultLink,
        place,
        placeUrl,
        invitations: invitations.map((i) => {
          return { email: i.email, member: i.member };
        }),
      });
      enqueueSnackbar('참여활동을 게시했습니다.', { variant: 'success' });
      setOpenPublishConfirm(false);
      history.replace(`/quests`);
    }
    setIsSaving(false);
  };

  const handleAddUser = (user: User) => {
    if (invitations.find((i) => i.email === user.email)) {
      enqueueSnackbar('이미 추가된 사용자입니다.', {
        variant: 'error',
      });
      return;
    }
    setInvitations((prev) => {
      return [...prev, { email: user.email, member: true, name: user.name }];
    });
    setSearchUserText('');
    setOpenUserList(false);
  };

  const handleRemoveUser = (email: string) => {
    const user = invitations.find((i) => i.email === email);
    if (!user) {
      enqueueSnackbar('삭제가 실패했습니다.', {
        variant: 'error',
      });
      return;
    }
    setInvitations((prev) => {
      return prev.filter((i) => i.email !== email);
    });
  };

  const handleUploadExcel = async (data: ArrayBuffer) => {
    /* data is an ArrayBuffer */
    const wb = read(data);
    const ws = wb.Sheets[wb.SheetNames[0]];
    const jsonData = utils.sheet_to_json<UserInvitationExcelFormat>(ws);
    jsonData.forEach((row) => {
      if (row.Name && row.Email) {
        if (invitations.find((i) => i.email === row.Email)) {
          enqueueSnackbar('이미 추가된 사용자입니다.', {
            variant: 'error',
          });
          return;
        }
        setInvitations((prev) => {
          return [...prev, { email: row.Email, member: false, name: row.Name }];
        });
      }
    });
  };
  return (
    <div>
      <Box display="flex">
        <Box
          display="flex"
          flexDirection="column"
          marginRight="51px"
          width="50%"
        >
          {quest && (
            <TextField
              id="id"
              name="id"
              label="참여활동 ID"
              InputProps={{
                readOnly: true,
              }}
              value={quest?.id}
              className={classes.input}
            />
          )}
          <TextField
            id="title"
            name="title"
            label="참여활동명"
            value={title}
            onChange={onChange}
            className={classes.input}
          />

          {categoryId !== categories.find((c) => c.value === 'poll')?.id ? (
            <>
              <KeyboardDatePicker
                format="yyyy-MM-dd"
                placeholder="yyyy-mm-dd"
                label="시작일시"
                minDate={!published ? new Date() : null}
                value={startAt}
                onChange={setStartAt}
                className={classes.input}
              />
              <Box className={classes.editorContainer}>
                <div className={classes.editorContainerTitle}>회의시간</div>
                <div className={classes.timeContainer}>
                  <Select
                    value={startTime.hour}
                    onChange={(e) => {
                      const expectedStartTime = `${e.target.value}:${startTime.min}`;
                      const expectedEndTime = `${endTime.hour}:${endTime.min}`;
                      if (expectedStartTime >= expectedEndTime) {
                        enqueueSnackbar(
                          '시작시간이 종료시간보다 크거나 같습니다.',
                          {
                            variant: 'error',
                          },
                        );
                        return;
                      }
                      setStartTime({
                        ...startTime,
                        hour: e.target.value as string,
                      });
                    }}
                  >
                    {Array.from(Array(24).keys())
                      .map((min) => (min < 10 ? `0${min}` : `${min}`))
                      .map((hour) => (
                        <MenuItem key={`h-${hour}`} value={hour}>
                          {hour}
                        </MenuItem>
                      ))}
                  </Select>{' '}
                  시{' '}
                  <Select
                    value={startTime.min}
                    onChange={(e) => {
                      const expectedStartTime = `${startTime.hour}:${e.target.value}`;
                      const expectedEndTime = `${endTime.hour}:${endTime.min}`;
                      if (expectedStartTime >= expectedEndTime) {
                        enqueueSnackbar(
                          '시작시간이 종료시간보다 크거나 같습니다.',
                          {
                            variant: 'error',
                          },
                        );
                        return;
                      }
                      setStartTime({
                        ...startTime,
                        min: e.target.value as string,
                      });
                    }}
                  >
                    {Array.from(Array(60).keys())
                      .filter((min) => min % 10 === 0)
                      .map((min) => (min < 10 ? `0${min}` : `${min}`))
                      .map((min) => (
                        <MenuItem key={`m-${min}`} value={min}>
                          {min}
                        </MenuItem>
                      ))}
                  </Select>
                  분<span className={classes.timeGap}>{' ~ '}</span>
                  <Select
                    value={endTime.hour}
                    onChange={(e) => {
                      const expectedStartTime = `${startTime.hour}:${startTime.min}`;
                      const expectedEndTime = `${e.target.value}:${endTime.min}`;
                      if (expectedStartTime >= expectedEndTime) {
                        enqueueSnackbar(
                          '시작시간이 종료시간보다 크거나 같습니다.',
                          {
                            variant: 'error',
                          },
                        );
                        return;
                      }
                      setEndTime({
                        ...endTime,
                        hour: e.target.value as string,
                      });
                    }}
                  >
                    {Array.from(Array(24).keys())
                      .map((min) => (min < 10 ? `0${min}` : `${min}`))
                      .map((hour) => (
                        <MenuItem key={`h-${hour}`} value={hour}>
                          {hour}
                        </MenuItem>
                      ))}
                  </Select>{' '}
                  시{' '}
                  <Select
                    value={endTime.min}
                    onChange={(e) => {
                      const expectedStartTime = `${startTime.hour}:${startTime.min}`;
                      const expectedEndTime = `${endTime.hour}:${e.target.value}`;
                      if (expectedStartTime >= expectedEndTime) {
                        enqueueSnackbar(
                          '시작시간이 종료시간보다 크거나 같습니다.',
                          {
                            variant: 'error',
                          },
                        );
                        return;
                      }
                      setEndTime({
                        ...endTime,
                        min: e.target.value as string,
                      });
                    }}
                  >
                    {Array.from(Array(60).keys())
                      .filter((min) => min % 10 === 0)
                      .map((min) => (min < 10 ? `0${min}` : `${min}`))
                      .map((min) => (
                        <MenuItem key={`m-${min}`} value={min}>
                          {min}
                        </MenuItem>
                      ))}
                  </Select>
                  분{' '}
                </div>
              </Box>

              {categoryId ===
              categories.find((c) => c.value === 'offline')?.id ? (
                <>
                  <TextField
                    id="place"
                    name="place"
                    label="회의장소명"
                    value={place}
                    onChange={onChange}
                    className={classes.input}
                  />
                  <TextField
                    id="placeUrl"
                    name="placeUrl"
                    label="회의장소URL"
                    value={placeUrl}
                    onChange={onChange}
                    className={classes.input}
                  />
                </>
              ) : (
                <TextField
                  id="link"
                  name="link"
                  label="회의 URL"
                  value={link}
                  onChange={onChange}
                  className={classes.input}
                />
              )}
            </>
          ) : (
            <>
              <KeyboardDatePicker
                format="yyyy-MM-dd"
                placeholder="yyyy-mm-dd"
                label="시작일시"
                value={startAt}
                minDate={!published ? new Date() : null}
                onChange={setStartAt}
                className={classes.input}
              />
              <KeyboardDatePicker
                format="yyyy-MM-dd"
                placeholder="yyyy-mm-dd"
                label="종료일시"
                minDate={startAt}
                minDateMessage="종료일시는 시작일시 후여야 합니다"
                value={endAt}
                onChange={setEndAt}
                className={classes.input}
              />
              <TextField
                id="link"
                name="link"
                label="설문 URL"
                value={link}
                onChange={onChange}
                className={classes.input}
              />
              <TextField
                id="resultLink"
                name="resultLink"
                label="설문 결과"
                value={resultLink}
                onChange={onChange}
                className={classes.input}
              />
            </>
          )}
        </Box>
        <Box display="flex" flexDirection="column" width="50%">
          {quest && (
            <TextField
              id="createdAt"
              name="createdAt"
              label="등록일시"
              value={new Date(quest?.createdAt).toLocaleString()}
              disabled
              className={classes.input}
            />
          )}
          <FormControl className={classes.input}>
            <InputLabel id="role-label">참여활동 유형</InputLabel>
            <Select
              labelId="category-label"
              id="categoryId"
              name="categoryId"
              value={categoryId}
              onChange={onChange}
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.label}
                </MenuItem>
              ))}
            </Select>
            {categoryId === categories.find((c) => c.value === 'poll')?.id && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={repeatable}
                    onChange={(e) =>
                      onChange({
                        target: { name: 'repeatable', value: e.target.checked },
                      })
                    }
                  />
                }
                label="중복설문"
                labelPlacement="end"
              />
            )}
          </FormControl>
          <FormControl className={classes.input}>
            <InputLabel id="project-label">대상 프로젝트</InputLabel>
            <Select
              labelId="project-label"
              id="projectId"
              name="projectId"
              value={projectId}
              onChange={onChange}
            >
              {projects.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap={16}>
        <FormControl
          component="fieldset"
          variant="standard"
          style={{ marginTop: 8 }}
          disabled={published}
        >
          <FormLabel component="legend">
            참여대상 ({allTarget ? '전체' : `${invitations.length}명`})
          </FormLabel>
          <FormGroup>
            {!allTarget && (
              <div className={classes.inviteesContainer}>
                {invitations
                  .sort((a, b) => {
                    // 1. sort by name
                    // 2. if name is same, sort by email
                    if (a.name > b.name) {
                      return 1;
                    } else if (a.name < b.name) {
                      return -1;
                    } else {
                      return a.email > b.email ? 1 : -1;
                    }
                  })
                  .map((invitation, idx) => (
                    <div className={classes.inviteeContainer} key={idx}>
                      <div className={classes.invitee}>
                        <div className={classes.inviteeName}>
                          {invitation.name}
                        </div>
                        <div className={classes.inviteeEmail}>
                          {invitation.email}
                        </div>
                      </div>
                      <div
                        className={classes.deleteInvitation}
                        onClick={() => {
                          if (published) return;
                          handleRemoveUser(invitation.email);
                        }}
                      >
                        {!published && <CancelIcon color="disabled" />}
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={allTarget}
                  onChange={(e) =>
                    onChange({
                      target: { name: 'allTarget', value: e.target.checked },
                    })
                  }
                  name="allTarget"
                />
              }
              label="전체 대상"
            />
          </FormGroup>
          {!allTarget && !published && (
            <>
              <FormLabel component="legend" style={{ marginTop: 8 }}>
                참여자등록
              </FormLabel>
              <FormGroup>
                <Box display="flex" width="100%" gridColumnGap={16}>
                  <TextField
                    id="searchUser"
                    name="searchUser"
                    hiddenLabel
                    placeholder="참여자를 검색해 주세요."
                    value={searchUserText}
                    onChange={(e) => setSearchUserText(e.target.value)}
                    onFocus={() => setOpenUserList(true)}
                    className={classes.searchInput}
                  />
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className={classes.dropzone}>단체등록하기</div>
                  </div>
                </Box>
                <Box display="flex" justifyContent="flex-end" marginTop={1}>
                  <Link href="/sample.xlsx" color="primary" underline="hover">
                    엑셀양식 다운로드
                  </Link>
                </Box>
                {openUserList && (
                  <div className={classes.usersContainer}>
                    {users
                      .sort((a, b) => {
                        // 1. sort by name
                        // 2. if name is same, sort by email
                        if (a.name > b.name) {
                          return 1;
                        } else if (a.name < b.name) {
                          return -1;
                        } else {
                          return a.email > b.email ? 1 : -1;
                        }
                      })
                      .filter((user) => user.admin === false)
                      .filter((user) =>
                        searchUserText === ''
                          ? true
                          : user.name.includes(searchUserText) ||
                            user.email.includes(searchUserText),
                      )
                      .map((user, idx) => (
                        <div
                          className={classes.invitee}
                          key={idx}
                          onClick={() => handleAddUser(user)}
                        >
                          <div className={classes.inviteeName}>{user.name}</div>
                          <div className={classes.inviteeEmail}>
                            {user.email}
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </FormGroup>
            </>
          )}
        </FormControl>
        <Box className={classes.editorContainer}>
          <div className={classes.editorContainerTitle}>상세소개</div>
          {quest && (
            <Editor
              ref={editorRef}
              initialValue={quest.editorContents}
              height="600px"
              initialEditType="wysiwyg"
              useCommandShortcut={true}
              usageStatistics={false}
              hooks={{
                addImageBlobHook: async (blob, callback) => {
                  try {
                    const formData = new FormData();
                    formData.append('image', blob);
                    const response = await axios.post<string>(
                      '/projects/upload-detail-image',
                      formData,
                      {
                        headers: { 'Content-Type': 'multipart/form-data' },
                      },
                    );
                    callback(
                      `${process.env.REACT_APP_BASE_URL}${response.data}`,
                      'uploaded',
                    );
                  } catch (e) {
                    enqueueSnackbar('이미지 업로드를 실패하였습니다.', {
                      variant: 'error',
                    });
                  }
                },
              }}
            />
          )}
          {!quest && (
            <Editor
              ref={editorRef}
              height="600px"
              initialEditType="wysiwyg"
              useCommandShortcut={true}
              usageStatistics={false}
              hooks={{
                addImageBlobHook: async (blob, callback) => {
                  try {
                    const formData = new FormData();
                    formData.append('image', blob);
                    const response = await axios.post<string>(
                      '/projects/upload-detail-image',
                      formData,
                      {
                        headers: { 'Content-Type': 'multipart/form-data' },
                      },
                    );
                    callback(
                      `${process.env.REACT_APP_BASE_URL}${response.data}`,
                      'uploaded',
                    );
                  } catch (e) {
                    enqueueSnackbar('이미지 업로드를 실패하였습니다.', {
                      variant: 'error',
                    });
                  }
                },
              }}
            />
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" marginBottom="40px">
        <Button variant="contained" color="primary" onClick={handleClickCancel}>
          취소
        </Button>
        <ProgressButton
          loading={isSaving}
          variant="contained"
          color="primary"
          className={classes.saveButton}
          onClick={handleClickSave}
          disabled={
            title === '' ||
            projectId === 0 ||
            (categoryId === categories.find((c) => c.value === 'poll')?.id
              ? !startAt || !endAt || startAt > endAt
              : !startTime || !endTime) ||
            (link === '' && placeUrl === '')
          }
        >
          {published ? '저장' : '임시저장'}
        </ProgressButton>
        <ProgressButton
          loading={isSaving}
          variant="contained"
          color="primary"
          className={classes.saveButton}
          onClick={() => setOpenPublishConfirm(true)}
          disabled={
            title === '' ||
            projectId === 0 ||
            (categoryId === categories.find((c) => c.value === 'poll')?.id
              ? !startAt || !endAt || startAt > endAt
              : !startTime || !endTime) ||
            (link === '' && placeUrl === '') ||
            published
          }
        >
          {published ? '공개됨' : '공개'}
        </ProgressButton>
      </Box>
      <Dialog
        open={openPublishConfirm}
        onClose={() => setOpenPublishConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">참여활동 공개</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            참여활동을 공개 하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenPublishConfirm(false)}
          >
            취소
          </Button>
          <ProgressButton
            loading={isSaving}
            variant="contained"
            color="primary"
            onClick={handleClickPublish}
            autoFocus
          >
            공개
          </ProgressButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
