import React, { useContext, createContext, useState } from 'react';
import axios from 'axios';

export const adminAuth = {
  async signIn(email: string, password: string): Promise<User> {
    const response = await axios.post('/admin/sign-in', {
      email,
      password,
    });

    const accessToken = response.headers['authorization'].slice(7);
    sessionStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('id', response.data.userId);
    sessionStorage.setItem('email', email);
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    return { id: response.data.userId, email };
  },
  async signOut(): Promise<void> {
    await axios.post('/sign-out');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('email');
    axios.defaults.headers.common['Authorization'] = undefined;
  },
  getUser(): User | undefined {
    const id = sessionStorage.getItem('id');
    const email = sessionStorage.getItem('email');
    if (id && email) {
      return {
        id: parseInt(id, 10),
        email,
      };
    }
  },
};

type User = {
  id: number;
  email: string;
};

type AuthType = {
  user: User | undefined;
  signIn: (
    email: string,
    password: string,
    onSuccess?: () => void,
    onError?: () => void,
  ) => void;
  signOut: (cb?: () => void) => void;
};
const authContext = createContext<AuthType>({
  user: undefined,
  signIn: () => undefined,
  signOut: () => undefined,
});

export default function useAuth(): AuthType {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState<User | undefined>(adminAuth.getUser());

  const signIn = async (
    email: string,
    password: string,
    onSuccess?: () => void,
    onError?: () => void,
  ) => {
    try {
      const user = await adminAuth.signIn(email, password);
      setUser(user);
      onSuccess?.();
    } catch (e) {
      onError?.();
    }
  };

  const signOut = (cb?: () => void) => {
    adminAuth.signOut();
    setUser(undefined);
    cb?.();
  };

  return {
    user,
    signIn,
    signOut,
  };
}

type Props = {
  children: JSX.Element;
};
export function ProvideAuth({ children }: Props): JSX.Element {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
