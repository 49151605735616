import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinkRouter from '../../components/LinkRouter';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import QuestInfo from './QuestInfo';
import { useParams } from 'react-router-dom';
import { Quest } from '../../common/types';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  root: {
    marginLeft: 56,
    marginRight: 56,
    display: 'flex',
    flexDirection: 'column',
  },
  breadcrumbs: {
    marginBottom: 17,
  },
});

export default function EditQuest(): JSX.Element {
  const classes = useStyles();
  const { questId } = useParams<{ questId: string }>();
  const [quest, setQuest] = useState<Quest>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function getQuest() {
      try {
        const response = await axios.get<Quest>(`/quests/${questId}`);
        setQuest(response.data);
      } catch (e) {
        enqueueSnackbar('퀘스트 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    getQuest();
  }, [questId]);

  return (
    <div>
      <Breadcrumbs className={classes.breadcrumbs}>
        <LinkRouter color="inherit" to="/quests">
          참여활동 관리
        </LinkRouter>
        <Typography color="textPrimary">{quest?.title}</Typography>
      </Breadcrumbs>
      <div className={classes.root}>
        <QuestInfo quest={quest} setQuest={setQuest} />
      </div>
    </div>
  );
}
