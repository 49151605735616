import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

interface Props {
  onSearch: (searchText: string) => void;
}

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'absolute',
    top: 27,
    right: 25,
    borderRadius: 70,
    backgroundColor: theme.palette.common.white,
    width: 248,
    border: '0.8px solid #A1A2AC',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    width: 'calc(100% - 60px)',
    paddingLeft: theme.spacing(2),
    borderBottom: 'none',
  },
}));

export default function SearchInput({ onSearch }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <TextField
        placeholder="Search…"
        className={classes.inputInput}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSearch(e.target.value);
          }
        }}
        InputProps={{ disableUnderline: true }}
      />
    </div>
  );
}
