import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import LinkRouter from '../../components/LinkRouter';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import TablePagination from '@material-ui/core/TablePagination';
import EmailIcon from '@material-ui/icons/Email';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Link from '@material-ui/core/Link';
import { Quest } from '../../common/types';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { parseQuestCategory } from '../../common/utils';
import SearchInput from '../../components/SearchInput';
import { AttendanceDialog } from './AttendanceDialog';
import { SummaryDialog } from './SummaryDialog';

const useStyles = makeStyles({
  breadcrumbs: {
    marginBottom: 56,
    fontSize: 20,
    color: '#050709',
  },
  addQuest: {
    height: 32,
  },
  table: {
    minWidth: 650,
  },
});

export default function Quests(): JSX.Element {
  const classes = useStyles();

  const [quests, setQuests] = useState<Quest[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const [titleQuery, setTitleQuery] = useState('');
  const [selectedQuest, setSelectedQuest] = useState<Quest | null>(null);
  const [openRsvpDialog, setOpenRsvpDialog] = useState(false);
  const [openSummaryDialog, setOpenSummaryDialog] = useState(false);
  const count = useMemo(
    () => quests.filter((quest) => quest.title.includes(titleQuery)).length,
    [quests, titleQuery],
  );
  const history = useHistory();

  useEffect(() => {
    setPage(0);
  }, [titleQuery]);

  useEffect(() => {
    async function getQuests() {
      try {
        const response = await axios.get<Quest[]>('/quests');
        setQuests(response.data);
      } catch (e) {
        enqueueSnackbar('참여활동 목록 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    getQuests();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickAdd = () => {
    history.push(`/quests/add`);
  };

  const handleClickEdit = (quest: Quest) => () => {
    history.push(`/quests/${quest.id}`);
  };
  const handleClickView = (quest: Quest) => () => {
    setSelectedQuest(quest);
    setOpenSummaryDialog(true);
  };
  const handleClickRsvp = (quest: Quest) => () => {
    setSelectedQuest(quest);
    setOpenRsvpDialog(true);
  };
  const handleClickDelete = (quest: Quest) => async () => {
    try {
      if (confirm('정말로 삭제하시겠습니까?')) {
        await axios.delete<null>(`/quests/${quest.id}`);
        enqueueSnackbar('참여활동을 삭제하였습니다.', { variant: 'success' });
        setQuests(quests.filter((q) => q.id !== quest.id));
      }
    } catch (e) {
      enqueueSnackbar('참여활동 삭제를 실패하였습니다.', { variant: 'error' });
    }
  };
  return (
    <div>
      <SearchInput onSearch={(text) => setTitleQuery(text)} />
      <Breadcrumbs className={classes.breadcrumbs}>
        <LinkRouter color="inherit" to="/quests">
          참여활동 관리
        </LinkRouter>
      </Breadcrumbs>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="24px"
      >
        <Button
          variant="contained"
          size="small"
          color="primary"
          startIcon={<AddRoundedIcon />}
          className={classes.addQuest}
          onClick={handleClickAdd}
        >
          참여활동 추가
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>참여활동 ID</TableCell>
              <TableCell>제목</TableCell>
              <TableCell align="center">유형</TableCell>
              <TableCell align="center">설문 결과</TableCell>
              <TableCell align="center">등록일시</TableCell>
              <TableCell align="center">관리</TableCell>
              <TableCell>RSVP</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quests
              .filter((quest) => quest.title.includes(titleQuery))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((quest) => (
                <TableRow key={quest.id}>
                  <TableCell component="th" scope="row">
                    {quest.id}
                  </TableCell>
                  <TableCell>{quest.title}</TableCell>
                  <TableCell align="center">
                    {quest.category.value === 'poll' && quest.repeatable
                      ? '중복설문조사'
                      : parseQuestCategory(quest.category)}
                  </TableCell>
                  <TableCell align="center">
                    {quest.resultLink ? (
                      <Link
                        href={quest.resultLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Link
                      </Link>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {!quest.published && '(임시)'}
                    {quest.publishedAt
                      ? new Date(quest.publishedAt).toLocaleString()
                      : new Date(quest.createdAt).toLocaleString()}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={handleClickView(quest)}
                      disabled={!quest.published}
                    >
                      <RemoveRedEyeIcon />
                    </IconButton>
                    <IconButton onClick={handleClickEdit(quest)}>
                      <EditRoundedIcon />
                    </IconButton>
                    <IconButton onClick={handleClickDelete(quest)}>
                      <DeleteRoundedIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      onClick={handleClickRsvp(quest)}
                      disabled={quest.category.value === 'poll'}
                    >
                      <EmailIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <AttendanceDialog
        quest={selectedQuest}
        open={openRsvpDialog}
        onClose={() => setOpenRsvpDialog(false)}
      />
      <SummaryDialog
        quest={selectedQuest}
        open={openSummaryDialog}
        onClose={() => setOpenSummaryDialog(false)}
      />
    </div>
  );
}
