import React from 'react';
import SignIn from './pages/SignIn';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import SideMenu from './components/SideMenu';
import { makeStyles } from '@material-ui/core/styles';
import ManageUsers from './pages/ManageUsers';
import ManageProjects from './pages/ManageProjects';
import ManageQuests from './pages/ManageQuests';
import ManageAdmins from './pages/ManageAdmins';
import MyPage from './pages/MyPage';
import ResetPassword from './pages/ResetPasswowrd';
import HomePage from './pages/HomePage';

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        auth.user ? (
          typeof children === 'function' ? (
            children(routeProps)
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

function App(): JSX.Element {
  const classes = useStyles();
  const auth = useAuth();
  return (
    <div className={classes.root}>
      {auth.user && <SideMenu />}
      <div className={classes.content}>
        <Switch>
          <Route exact path="/">
            <SignIn />
          </Route>
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
          <PrivateRoute path="/home">
            <HomePage />
          </PrivateRoute>
          <PrivateRoute path="/my-page">
            <MyPage />
          </PrivateRoute>
          <PrivateRoute path="/users">
            <ManageUsers />
          </PrivateRoute>
          <PrivateRoute path="/projects">
            <ManageProjects />
          </PrivateRoute>
          <PrivateRoute path="/quests">
            <ManageQuests />
          </PrivateRoute>
          <PrivateRoute path="/admins">
            <ManageAdmins />
          </PrivateRoute>
          <Route path="*">
            <h3>404 Not Found</h3>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
