import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Projects from './Projects';
import AddProject from './AddProject';
import EditProject from './EditProject';

export default function ManageProjects(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Projects />
      </Route>
      <Route path={`${path}/add`}>
        <AddProject />
      </Route>
      <Route path={`${path}/:projectId`}>
        <EditProject />
      </Route>
    </Switch>
  );
}
