import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

interface ProgressButtonProps extends ButtonProps {
  children?: React.ReactNode;
  loading: boolean;
}
export default function ProgressButton({
  children,
  loading,
  disabled,
  className,
  ...other
}: ProgressButtonProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${className || ''}`}>
      <Button disabled={disabled || loading} {...other}>
        {children}
      </Button>
      {loading && <CircularProgress size={24} className={classes.progress} />}
    </div>
  );
}
