import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Quest, QuestInvitation } from '../../common/types';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import {
  parseDate,
  parseDateTime,
  parseQuestCategory,
} from '../../common/utils';
import { Viewer } from '@toast-ui/react-editor';
import { UserInvitation } from './QuestInfo';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    color: '#292A33',
    fontWeight: 500,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    border: '1px solid #D5D7E5',
    borderRadius: 8,
    margin: 24,
    padding: 24,
    maxHeight: '50vh',
    overflowY: 'scroll',
  },
  invitations: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 8,
    columnGap: 24,
    border: '1px solid #D5D7E5',
    borderRadius: 8,
    padding: 24,
    maxHeight: 230,
    minHeight: 30,
    overflowY: 'scroll',
    marginBottom: 8,
  },
  invitee: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 162,
  },
  inviteeName: {
    fontSize: 14,
    fontWeight: 700,
    color: '#292A33',
  },
  inviteeEmail: {
    fontSize: 14,
    fontWeight: 500,
    color: '#777B8C',
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
    marginTop: 16,
  },
  editorContainerTitle: {
    marginBottom: 4,
    fontSize: 14,
    color: '#696969',
  },
});

interface Props {
  quest: Quest | null;
  open: boolean;
  onClose: () => void;
}
export const SummaryDialog = ({ quest, open, onClose }: Props) => {
  const classes = useStyles();
  const [questInvitations, setQuestInvitations] = useState<UserInvitation[]>(
    [],
  );
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function getQuestInvitations() {
      try {
        const response = await axios.get<UserInvitation[]>(
          `/quests/${quest!.id}/invitees`,
        );
        setQuestInvitations(response.data);
      } catch (e) {
        enqueueSnackbar('참여자 명단 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    if (open && quest) {
      getQuestInvitations();
    }
  }, [quest]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle className={classes.title}>참여활동 상세내용</DialogTitle>
      {quest && (
        <DialogContent className={classes.content}>
          <Box display="flex" width={'100%'}>
            <Box
              display="flex"
              flexDirection="column"
              marginRight="16px"
              gridRowGap={8}
              width={'55%'}
            >
              <TextField
                id="id"
                name="id"
                label="참여활동 ID"
                value={quest.id}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                id="title"
                name="title"
                label="참여활동명"
                value={quest.title}
                multiline
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                id="startAt"
                name="startAt"
                label="시작일시"
                value={
                  quest.category.value !== 'poll'
                    ? parseDateTime(new Date(quest.startAt), '-')
                    : parseDate(new Date(quest.startAt), '-')
                }
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                id="endAt"
                name="endAt"
                label="종료일시"
                value={
                  quest.category.value !== 'poll'
                    ? parseDateTime(new Date(quest.endAt), '-')
                    : parseDate(new Date(quest.endAt), '-')
                }
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gridRowGap={8}
              width={'45%'}
            >
              <TextField
                id="category"
                name="category"
                label="참여활동 유형"
                value={`${parseQuestCategory(quest.category)}${
                  quest.category.value === 'poll' && quest.repeatable
                    ? '(중복설문)'
                    : ''
                }`}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                id="project"
                name="project"
                label="대상 알아보기"
                multiline
                value={quest.project.title}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
          {quest.category.value === 'poll' && (
            <>
              <TextField
                id="link"
                name="link"
                label="설문URL"
                multiline
                value={quest.link}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                id="resultLink"
                name="resultLink"
                label="설문결과URL"
                multiline
                value={quest.resultLink}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </>
          )}
          {quest.category.value === 'offline' && (
            <>
              <TextField
                id="place"
                name="place"
                label="회의장소명"
                multiline
                value={quest.place}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                id="placeUrl"
                name="placeUrl"
                label="회의장소URL"
                multiline
                value={quest.placeUrl}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </>
          )}
          {(quest.category.value === 'online' ||
            quest.category.value === 'metaverse') && (
            <>
              <TextField
                id="link"
                name="link"
                label="회의URL"
                value={quest.link}
                multiline
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                id="resultLink"
                name="resultLink"
                label="회의결과URL"
                value={quest.resultLink}
                multiline
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </>
          )}
          <TextField
            id="target"
            name="target"
            label="참여대상"
            value={quest.allTarget ? '전체' : `${questInvitations.length}명`}
            InputProps={{
              disableUnderline: true,
              readOnly: true,
            }}
          />
          {!quest.allTarget && (
            <div className={classes.invitations}>
              {questInvitations
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((invitation, idx) => (
                  <div className={classes.invitee} key={idx}>
                    <div className={classes.inviteeName}>{invitation.name}</div>
                    <div className={classes.inviteeEmail}>
                      {invitation.email}
                    </div>
                  </div>
                ))}
            </div>
          )}
          <Box className={classes.editorContainer}>
            <div className={classes.editorContainerTitle}>상세소개</div>
            <Viewer
              initialValue={quest.editorContents}
              usageStatistics={false}
            />
          </Box>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};
