import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Admins from './Admins';
import AddAdmin from './AddAdmin';

export default function ManageAdmins(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Admins />
      </Route>
      <Route path={`${path}/add`}>
        <AddAdmin />
      </Route>
    </Switch>
  );
}
