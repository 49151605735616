import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { adminAuth, ProvideAuth } from './hooks/useAuth';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

if (sessionStorage.getItem('accessToken')) {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${sessionStorage.getItem('accessToken')}`;
}
axios.interceptors.response.use(undefined, async function (error) {
  if (error.response.status === 401 && error.config.url !== '/admin/sign-in') {
    await adminAuth.signOut();
    window.location.reload();
  }
  return Promise.reject(error);
});
axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/api/v1`;
axios.defaults.withCredentials = true;

const theme = createTheme({
  palette: {
    primary: {
      main: '#3740DD',
    },
    text: {
      primary: '#050709',
      secondary: '#696969',
    },
    background: {
      default: '#FFFFFF',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ProvideAuth>
      <Router>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Router>
    </ProvideAuth>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
