import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LinkRouter from '../../components/LinkRouter';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ProjectInfo from './ProjectInfo';
import RelatedQuests from './RelatedQuests';
import TabPanel from '../../components/TabPanel';
import axios from 'axios';
import { Project } from '../../common/types';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  root: {
    marginLeft: 56,
    marginRight: 56,
    display: 'flex',
    flexDirection: 'column',
  },
  breadcrumbs: {
    marginBottom: 17,
    color: '#050709',
  },
});

export default function EditProject(): JSX.Element {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const { projectId } = useParams<{ projectId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [project, setProject] = useState<Project>();

  useEffect(() => {
    async function getProject() {
      try {
        const response = await axios.get<Project>(`/projects/${projectId}`);
        setProject(response.data);
      } catch (e) {
        enqueueSnackbar('프로젝트 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    getProject();
  }, [projectId]);

  const handleChangeTab = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Breadcrumbs className={classes.breadcrumbs}>
        <LinkRouter color="inherit" to="/projects">
          알아보기 관리
        </LinkRouter>
        <Typography color="textPrimary">{project?.title}</Typography>
      </Breadcrumbs>
      <div className={classes.root}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="기본정보" />
          <Tab label="연관 참여활동" />
        </Tabs>
        <TabPanel index={0} value={tabValue}>
          <ProjectInfo project={project} setProject={setProject} />
        </TabPanel>
        <TabPanel index={1} value={tabValue}>
          <RelatedQuests projectId={parseInt(projectId, 10)} />
        </TabPanel>
      </div>
    </div>
  );
}
