import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Quests from './Quests';
import EditQuest from './EditQuest';
import AddQuest from './AddQuest';

export default function ManageQuests(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Quests />
      </Route>
      <Route path={`${path}/add`}>
        <AddQuest />
      </Route>
      <Route path={`${path}/:questId`}>
        <EditQuest />
      </Route>
    </Switch>
  );
}
