import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDropzone } from 'react-dropzone';
import useInputs from '../../hooks/useInputs';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ProjectCategory, Project } from '../../common/types';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import ProgressButton from '../../components/ProgressButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Radio, RadioGroup } from '@material-ui/core';
import {
  getAllProjectSubcategories,
  getProjectSubcategories,
} from '../../common/utils';

import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';

const useStyles = makeStyles({
  saveButton: {
    marginLeft: 10,
  },
  dropzone: {
    width: 320,
    height: 180,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.54)',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    '& p': {
      marginTop: 6,
    },
  },
  preview: {
    width: 335,
    height: 174,
    display: 'block',
    objectFit: 'cover',
    borderRadius: 5,
  },
  removeImage: {
    marginTop: 8,
  },
  oneLineInput: {
    marginBottom: 16,
    width: 220,
  },
  fullWidthInput: {
    marginBottom: 16,
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
    marginTop: 16,
  },
  editorContainerTitle: {
    marginBottom: 4,
    fontSize: 14,
    color: '#696969',
  },
});

interface ProjectInfoProps {
  project?: Project;
  setProject?: (project: Project) => void;
}
export default function ProjectInfo({
  project,
  setProject,
}: ProjectInfoProps): JSX.Element {
  const classes = useStyles();

  const [categories, setCategories] = useState<ProjectCategory[]>([]);
  const [subcategories, setSubcategories] = useState<string[]>([]);
  const [files, setFiles] = useState<(File & { preview: string })[]>([]);
  const [excludeFromCount, setExcludeFromCount] = useState(false);
  const [showSubcategoryInput, setShowSubcategoryInput] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [resourceLoaded, setResourceLoaded] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });
  const [
    { title, categoryId, shortDescription, subcategory },
    onChange,
    reset,
  ] = useInputs({
    title: '',
    categoryId: 0,
    subcategory: '',
    shortDescription: '',
    editorContents: '',
  });
  const history = useHistory();
  const editorRef = useRef<any>(null);

  useEffect(() => {
    if (project && resourceLoaded) {
      reset({
        title: project.title,
        categoryId: project.category.id,
        link: project.link,
        shortDescription: project.shortDescription,
        editorContents: project.editorContents,
        subcategory: project.subcategory,
      });
      setExcludeFromCount(project.excludeFromCount);
    }
  }, [resourceLoaded, project]);

  useEffect(() => {
    if (categoryId) {
      const category = categories.find(
        (category) => category.id === categoryId,
      );
      if (category) {
        const currentSubCategories = getProjectSubcategories(category.value);
        setSubcategories(currentSubCategories);

        // edit project page
        if (project) {
          // handle the case when the subcategory is user-defined
          if (
            project.category.id == categoryId &&
            !getAllProjectSubcategories().includes(project.subcategory)
          ) {
            setShowSubcategoryInput(true);
            onChange({
              target: {
                name: 'subcategory',
                value: project.subcategory,
              },
            });
          } else {
            // handle the case when the subcategory is not predefined
            setShowSubcategoryInput(false);
            if (currentSubCategories.length > 0) {
              onChange({
                target: {
                  name: 'subcategory',
                  value:
                    currentSubCategories.find(
                      (c) => c === project.subcategory,
                    ) || currentSubCategories[0],
                },
              });
            }
          }
          // add project page
        } else {
          setSubcategories(currentSubCategories);
          onChange({
            target: {
              name: 'subcategory',
              value: currentSubCategories.find(
                (c) => c === currentSubCategories[0],
              ),
            },
          });
        }
      }
    } else {
      setSubcategories([]);
    }
  }, [categoryId]);
  useEffect(() => {
    async function getCategories() {
      try {
        const response = await axios.get<ProjectCategory[]>('/pcategories');
        setCategories(response.data);
      } catch (e) {
        enqueueSnackbar('카테고리 목록 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    async function getResources() {
      await getCategories();
      setResourceLoaded(true);
    }
    getResources();
  }, []);

  const handleClickCancel = () => {
    history.push('/projects');
  };
  const handleClickSave = async () => {
    setIsSaving(true);
    if (project) {
      try {
        const updateProject = () => {
          return axios.patch<Project>(`/projects/${project.id}`, {
            title,
            subcategory,
            shortDescription,
            editorContents: editorRef.current.getInstance().getHTML(),
            categoryId,
            excludeFromCount,
          });
        };
        const updateThumbnail = () => {
          if (files[0]) {
            const formData = new FormData();
            formData.append('thumbnail', files[0]);
            return axios.post<Project>(
              `/projects/${project.id}/change-thumbnail`,
              formData,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              },
            );
          }
        };

        const [projectResponse, thumbnailResponse] = await Promise.all([
          updateProject(),
          updateThumbnail(),
        ]);
        const updatedProject = {
          ...projectResponse.data,
        };
        if (thumbnailResponse) {
          updatedProject.thumbnailUrl = thumbnailResponse.data.thumbnailUrl;
          setFiles([]);
        }
        setProject?.(updatedProject);
        enqueueSnackbar('프로젝트를 수정하였습니다.', { variant: 'success' });
        history.replace('/projects');
      } catch (e) {
        enqueueSnackbar('프로젝트 수정을 실패하였습니다.', {
          variant: 'error',
        });
      }
    } else {
      try {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('subcategory', subcategory);
        formData.append('shortDescription', shortDescription);
        formData.append(
          'editorContents',
          editorRef.current.getInstance().getHTML(),
        );
        formData.append('categoryId', categoryId);
        formData.append('thumbnail', files[0]);
        formData.append('excludeFromCount', 'false');

        const response = await axios.post<Project>('/projects', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        enqueueSnackbar('프로젝트를 생성하였습니다.', { variant: 'success' });
        history.replace('/projects');
      } catch (e) {
        enqueueSnackbar('프로젝트 생성을 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    setIsSaving(false);
  };
  const handleSubcategoryChange = (value: string) => {
    if (value === 'input') {
      value = '';
      setShowSubcategoryInput(true);
    } else {
      setShowSubcategoryInput(false);
    }
    onChange({ target: { name: 'subcategory', value } });
  };

  return (
    <Box>
      <Box display="flex" marginTop="20px">
        <Box
          display="flex"
          flexDirection="column"
          marginRight="16px"
          marginBottom="16px"
        >
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {files.length > 0 ? (
              <img
                src={files[0].preview}
                alt={files[0].name}
                className={classes.preview}
              />
            ) : project?.thumbnailUrl ? (
              <img
                src={
                  project.thumbnailUrl.includes('https://')
                    ? project.thumbnailUrl
                    : `${process.env.REACT_APP_BASE_URL}${project.thumbnailUrl}`
                }
                alt={project.title}
                className={classes.preview}
              />
            ) : (
              <div className={classes.dropzone}>
                <ImageRoundedIcon fontSize="large" />
                <Typography variant="body2" color="textSecondary" component="p">
                  클릭 또는 업로드 할 이미지를 드래그해주세요.
                </Typography>
              </div>
            )}
          </div>
        </Box>
        <Box display="flex" flexDirection="column">
          {project && (
            <TextField
              id="id"
              name="id"
              label="참여하기 ID"
              InputProps={{
                readOnly: true,
              }}
              value={project?.id}
              className={classes.fullWidthInput}
            />
          )}
          <FormControl className={classes.fullWidthInput}>
            <InputLabel id="role-label">카테고리</InputLabel>
            <Select
              labelId="category-label"
              id="categoryId"
              name="categoryId"
              value={categoryId}
              onChange={onChange}
            >
              <MenuItem value={0}>(카테고리 선택)</MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.label}
                </MenuItem>
              ))}
            </Select>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={showSubcategoryInput ? 'input' : subcategory}
              name="radio-buttons-group"
              onChange={(e) => handleSubcategoryChange(e.target.value)}
            >
              {subcategories.map((value, idx) => (
                <FormControlLabel
                  key={idx}
                  value={value}
                  control={<Radio color="default" />}
                  label={value}
                />
              ))}
              {categoryId !== 0 && (
                <FormControlLabel
                  value="input"
                  control={<Radio color="default" />}
                  label="직접입력"
                />
              )}
            </RadioGroup>
            {showSubcategoryInput && (
              <TextField
                id="subcategory"
                name="subcategory"
                hiddenLabel
                value={subcategory}
                fullWidth
                onChange={onChange}
                className={classes.fullWidthInput}
              />
            )}
          </FormControl>
        </Box>
      </Box>
      <TextField
        id="title"
        name="title"
        label="프로젝트명"
        value={title}
        fullWidth
        onChange={onChange}
        className={classes.fullWidthInput}
      />
      <TextField
        id="shortDescription"
        name="shortDescription"
        label="한줄소개"
        multiline
        fullWidth
        value={shortDescription}
        onChange={onChange}
        className={classes.fullWidthInput}
      />
      <Box className={classes.editorContainer}>
        <div className={classes.editorContainerTitle}>상세소개</div>
        {project && (
          <Editor
            ref={editorRef}
            initialValue={project.editorContents}
            height="600px"
            initialEditType="wysiwyg"
            useCommandShortcut={true}
            usageStatistics={false}
            hooks={{
              addImageBlobHook: async (blob, callback) => {
                try {
                  const formData = new FormData();
                  formData.append('image', blob);
                  const response = await axios.post<string>(
                    '/projects/upload-detail-image',
                    formData,
                    {
                      headers: { 'Content-Type': 'multipart/form-data' },
                    },
                  );
                  callback(
                    `${process.env.REACT_APP_BASE_URL}${response.data}`,
                    'uploaded',
                  );
                } catch (e) {
                  enqueueSnackbar('이미지 업로드를 실패하였습니다.', {
                    variant: 'error',
                  });
                }
              },
            }}
          />
        )}
        {!project && (
          <Editor
            ref={editorRef}
            height="600px"
            initialEditType="wysiwyg"
            useCommandShortcut={true}
            usageStatistics={false}
            hooks={{
              addImageBlobHook: async (blob, callback) => {
                try {
                  const formData = new FormData();
                  formData.append('image', blob);
                  const response = await axios.post<string>(
                    '/projects/upload-detail-image',
                    formData,
                    {
                      headers: { 'Content-Type': 'multipart/form-data' },
                    },
                  );
                  callback(
                    `${process.env.REACT_APP_BASE_URL}${response.data}`,
                    'uploaded',
                  );
                } catch (e) {
                  enqueueSnackbar('이미지 업로드를 실패하였습니다.', {
                    variant: 'error',
                  });
                }
              },
            }}
          />
        )}
      </Box>
      {project && project.id !== 0 && (
        <>
          <Box display="flex" flexDirection="column">
            <TextField
              id="createdAt"
              name="createdAt"
              fullWidth
              label="등록일시"
              value={new Date(project.createdAt).toLocaleString()}
              disabled
              className={classes.fullWidthInput}
            />
            <TextField
              id="likeCount"
              name="likeCount"
              label="좋아요"
              value={project.likeCount}
              disabled
              className={classes.oneLineInput}
            />
          </Box>
        </>
      )}
      <Box display="flex" marginBottom="40px" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={handleClickCancel}>
          취소
        </Button>
        <ProgressButton
          loading={isSaving}
          variant="contained"
          color="primary"
          className={classes.saveButton}
          onClick={handleClickSave}
          disabled={
            title === '' ||
            categoryId === 0 ||
            shortDescription === '' ||
            (!project && files.length === 0)
          }
        >
          저장
        </ProgressButton>
      </Box>
    </Box>
  );
}
