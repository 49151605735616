import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Users from './Users';
import User from './User';

export default function ManageUsers(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Users />
      </Route>
      <Route path={`${path}/:userId`}>
        <User />
      </Route>
    </Switch>
  );
}
