import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import useInputs from '../hooks/useInputs';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { validateEmail } from '../common/utils';
import ProgressButton from '../components/ProgressButton';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  root: {
    marginTop: 120,
  },
  input: {
    marginTop: 16,
  },
  saveButton: {
    marginLeft: 10,
  },
});

export default function ResetPassword(): JSX.Element {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [{ email }, onChange] = useInputs({
    email: '',
  });
  const history = useHistory();

  const handleClickCancel = () => {
    history.push('/');
  };
  const handleClickSave = async () => {
    try {
      setIsLoading(true);
      await axios.post<null>('/admin/reset-password', {
        email,
      });
      setCurrentTab(1);
    } catch (e) {
      enqueueSnackbar('비밀번호 초기화를 실패하였습니다.', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      {currentTab === 0 ? (
        <>
          <Typography variant="h6" component="p">
            패스워드 초기화
          </Typography>
          <TextField
            id="email"
            name="email"
            type="email"
            label="이메일"
            fullWidth
            error={email && !validateEmail(email)}
            value={email}
            onChange={onChange}
            className={classes.input}
          />
          <Box display="flex" justifyContent="flex-end" marginTop="16px">
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickCancel}
            >
              취소
            </Button>
            <ProgressButton
              loading={isLoading}
              variant="contained"
              color="primary"
              className={classes.saveButton}
              onClick={handleClickSave}
              disabled={email === '' || !validateEmail(email)}
            >
              확인
            </ProgressButton>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="h4" component="p">
            비밀번호 재설정 메일 발송
          </Typography>
          <Typography component="p" style={{ marginTop: 16 }}>
            <br />
            <span style={{ color: '#3740DD' }}>{email}</span> 으로 <br />
            초기화된 비밀번호가 발송되었습니다
            <br />
            <br />
            발송된 임시비밀번호로 로그인하신 후, <br /> 비밀번호를 변경하시길
            바랍니다.
          </Typography>
          <br />
          <br />
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={() => history.replace('/')}
          >
            홈으로 이동
          </Button>
        </>
      )}
    </Container>
  );
}
