import React, { useEffect, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Quest } from '../../common/types';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  breadcrumbs: {
    marginBottom: 56,
  },
  searchInput: {
    width: 220,
  },
  table: {
    minWidth: 650,
  },
});

interface RelatedQuestsProps {
  projectId: number;
}
export default function RelatedQuests({
  projectId,
}: RelatedQuestsProps): JSX.Element {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [quests, setQuests] = useState<Quest[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function getQuests() {
      try {
        const response = await axios.get<Quest[]>(
          `/projects/${projectId}/quests`,
        );
        setQuests(response.data);
      } catch (e) {
        enqueueSnackbar('퀘스트 목록 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    getQuests();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box marginTop="32px">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>참여활동 ID</TableCell>
              <TableCell>참여활동명</TableCell>
              <TableCell>참여활동 시작일시</TableCell>
              <TableCell>참여활동 종료일시</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quests
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((quest) => (
                <TableRow key={quest.id}>
                  <TableCell>{quest.id}</TableCell>
                  <TableCell>{quest.title}</TableCell>
                  <TableCell>{quest.startAt}</TableCell>
                  <TableCell>{quest.endAt}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={quests.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
