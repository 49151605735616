import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useInputs from '../hooks/useInputs';
import useAuth from '../hooks/useAuth';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import LinkRouter from '../components/LinkRouter';
import Logo from '../images/logo.png';
import LogoText from '../images/logoText.png';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 292,
    padding: 0,
  },
  paper: {
    marginTop: 196,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 82,
    height: 97,
  },
  logoText: {
    width: 82,
    height: 33,
    marginTop: 3,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  submit: {
    marginTop: 18,
    height: 40,
  },
  forgotPassword: {
    marginTop: 14,
    fontSize: 12,
    color: '#696969',
  },
  headerText: {
    position: 'absolute',
    top: 30,
    left: 25,
    color: theme.palette.primary.main,
  },
}));

export default function SignIn(): JSX.Element {
  const classes = useStyles();
  const [{ email, password }, onChange] = useInputs({
    email: '',
    password: '',
  });
  const [error, setError] = useState(false);

  const auth = useAuth();
  const history = useHistory();
  const location = useLocation<{ from: LocationDescriptor }>();

  useEffect(() => {
    setError(false);
  }, [email, password]);

  const signIn = (email: string, password: string) => {
    auth.signIn(
      email,
      password,
      () => {
        const { from } = location.state || { from: { pathname: '/home' } };
        history.replace(from);
      },
      () => {
        setError(true);
      },
    );
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    signIn(email, password);
  };

  return (
    <Container component="main" className={classes.root}>
      <Typography className={classes.headerText}>관리자 시스템</Typography>
      <div className={classes.paper}>
        <img src={Logo} alt="logo" className={classes.logo} />
        <img src={LogoText} alt="logo-text" className={classes.logoText} />
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="ID(Email)"
            name="email"
            autoComplete="email"
            autoFocus
            error={error}
            helperText={error && 'Check email again'}
            value={email}
            onChange={onChange}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="비밀번호"
            type="password"
            id="password"
            autoComplete="current-password"
            error={error}
            helperText={error && 'Check password again'}
            value={password}
            onChange={onChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            로그인
          </Button>
        </form>
        <LinkRouter to="/reset-password" className={classes.forgotPassword}>
          비밀번호 초기화
        </LinkRouter>
      </div>
    </Container>
  );
}
