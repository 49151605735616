import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import ViewListRoundedIcon from '@material-ui/icons/ViewListRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Omit } from '@material-ui/types';
import useAuth from '../hooks/useAuth';
import Button from '@material-ui/core/Button';
import WhiteLogoAndText from '../images/whiteLogoAndText.png';
import axios from 'axios';
import { User } from '../common/types';
import { useSnackbar } from 'notistack';

const Menus = [
  {
    text: '홈',
    icon: <HomeRoundedIcon />,
    to: '/home',
  },
  {
    text: '사용자 관리',
    icon: <PeopleRoundedIcon />,
    to: '/users',
  },
  {
    text: '알아보기 관리',
    icon: <ViewListRoundedIcon />,
    to: '/projects',
  },
  {
    text: '참여활동 관리',
    icon: <AssignmentRoundedIcon />,
    to: '/quests',
  },
  {
    text: '관리자 설정',
    icon: <SettingsIcon />,
    to: '/admins',
  },
];

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  selected: boolean;
}
function ListItemLink({
  icon,
  primary,
  to,
  selected,
}: ListItemLinkProps): JSX.Element {
  const renderLink = React.useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {selected && (
          <div
            style={{
              width: 9,
              height: 32,
              position: 'absolute',
              left: -4,
              backgroundColor: '#FFFFFF',
              borderRadius: 29,
            }}
          />
        )}
        {icon ? (
          <ListItemIcon
            style={{
              color: selected ? '#FFFFFF' : 'rgba(255, 255, 255, 0.65)',
            }}
          >
            {icon}
          </ListItemIcon>
        ) : null}
        <ListItemText
          primary={primary}
          style={{
            color: selected ? '#FFFFFF' : 'rgba(255, 255, 255, 0.65)',
          }}
        />
      </ListItem>
    </li>
  );
}

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main,
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '0px 5px 5px 0px',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 106,
    height: 30,
    marginTop: 23,
    marginLeft: 9,
  },
  title: {
    marginLeft: 52,
    marginTop: 3,
    marginBottom: 60,
  },
  email: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  bottomList: {
    marginTop: 'auto',
    marginBottom: 80,
  },
  signOut: {
    color: 'rgba(255, 255, 255, 0.65)',
    justifyContent: 'flex-start',
    padding: '8px 16px',
    height: 48,
    fontSize: '1rem',
  },
  signOutIcon: {
    marginRight: 32,
  },
}));

export default function SideMenu(): JSX.Element {
  const classes = useStyles();
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [userName, setUserName] = useState('');

  useEffect(() => {
    async function getMyInfo() {
      try {
        const response = await axios.get<User>(`/users/${auth.user?.id}`);
        setUserName(response.data.name);
      } catch (e) {
        enqueueSnackbar('내 정보 조회를 실패하였습니다.', {
          variant: 'error',
        });
      }
    }
    getMyInfo();
  }, []);
  const handleClickSignOut = () => {
    auth.signOut(() => {
      history.push('/');
    });
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <img src={WhiteLogoAndText} alt="logo" className={classes.logo} />
      <Typography variant="body2" className={classes.title}>
        Admin
      </Typography>
      <List>
        {Menus.map((menu) => (
          <ListItemLink
            key={menu.text}
            icon={menu.icon}
            primary={menu.text}
            to={menu.to}
            selected={menu.to === location.pathname}
          />
        ))}
      </List>
      <List className={classes.bottomList}>
        <ListItemLink
          icon={<AccountCircleRoundedIcon />}
          primary={`${userName} 님`}
          to="/my-page"
          selected={'/my-page' === location.pathname}
        />
        <Button className={classes.signOut} onClick={handleClickSignOut}>
          <ExitToAppRoundedIcon className={classes.signOutIcon} />
          로그아웃
        </Button>
      </List>
    </Drawer>
  );
}
