import { QuestCategory } from './types';

export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getProjectSubcategories(value: string) {
  switch (value) {
    case 'news':
      return ['공지사항', '이벤트', '행사'];
    case 'smartcity':
      return ['예비사업 프로젝트', '본사업 프로젝트', '스마트시티 포항'];
    case 'citylab':
      return ['시티랩이란', '시티랩 활동소식', '자주묻는질문'];
    default:
      return [];
  }
}

export function getAllProjectSubcategories(): string[] {
  return [
    '공지사항',
    '이벤트',
    '행사',
    '예비사업 프로젝트',
    '본사업 프로젝트',
    '스마트시티 포항',
    '시티랩이란',
    '시티랩 활동소식',
    '자주묻는질문',
  ];
}

export function parseQuestCategory(category: QuestCategory) {
  switch (category.value) {
    case 'poll':
      return '설문조사';
    case 'offline':
      return '대면회의';
    case 'online':
      return '화상회의';
    case 'metaverse':
      return '메타버스';
    default:
      return '';
  }
}

const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];
export function parseDate(
  date: Date,
  separator = '.',
  shorten = false,
  includeDay = false,
) {
  let year = date.getFullYear().toString();
  if (shorten) {
    year = year.slice(2);
  }
  let str = `${year}${separator}${('0' + (date.getMonth() + 1)).slice(
    -2,
  )}${separator}${('0' + date.getDate()).slice(-2)}`;
  if (includeDay) {
    str = `${str}.(${WEEKDAY[date.getDay()]})`;
  }
  return str;
}

export function parseTime(date: Date, separator = ':') {
  return `${('0' + date.getHours()).slice(-2)}${separator}${(
    '0' + date.getMinutes()
  ).slice(-2)}`;
}

export function parseDateTime(
  date: Date,
  dateSeparator = '.',
  timeSeparator = ':',
) {
  return `${parseDate(date, dateSeparator)} ${parseTime(date, timeSeparator)}`;
}
